

let actions,
    hold = false;

export function setBindedActions(_actions) {
    actions = _actions;
};


const applyResize = () => {
    actions.windowResized();
    hold = false;
};

const start = () => {
    if (hold !== true) {
        hold = true;
        window.requestAnimationFrame(applyResize);
    }
};


export const init = () => {
    window.addEventListener('resize', start);
};