
// Libs
import React, { Component }   from 'react';
import PropTypes              from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect }            from 'react-redux';

// App modules
import AppToolbar from 'src/components/app-toolbar/AppToolbar';
import Menu     from 'src/components/menu/Menu';
import MediasPageContent from './MediasPageContent';

import { MEDIAS_PAGE_KEY } from 'src/pages/pagesKeys';
import * as actions from 'src/store/actions';

import './MediasPage.scss';


const LOG_PREF = '[MediasPage] ';


class MediasPage extends Component {

    constructor(props) {
        super(props);
        this.pageKey = MEDIAS_PAGE_KEY;
    }

    shouldComponentUpdate(nextProps) {
        return (JSON.stringify(this.props) !== JSON.stringify(nextProps))
    }

    /**
     * Update string visible in browser tab/history/favorites
     */
    setDocumentContext() {
        if (this.props.isActive(this.pageKey)) {
            this.props.setDocumentContext(this.getPageTitle());
        }
    }

    /**
     * String displayed in app toolbar
     * @return {string}
     */
    getPageTitle() {
        return this.props.labels.medias.title;
    }

    componentDidMount() {
        this.setDocumentContext();
    }
    componentDidUpdate() {
        this.setDocumentContext();
    }

    render() {
        console.log(LOG_PREF+'render');
        return (
            <Menu
                options={{ isOpen: this.props.isMenuOpen }}
                actions={this.props.actions}
                labels={this.props.labels}
                profile={this.props.profile}
                associatedPageKey={this.pageKey}
                adConfig={this.props.adConfig}
                twoColumns={this.props.twocolumns}
                isLocationEnabled={this.props.isLocationEnabled}>

                <AppToolbar
                    labels={this.props.labels}
                    isDisplayed={this.props.hasToolbar}
                    actions={this.props.actions}
                    title={this.getPageTitle()}
                    pageKey={this.pageKey}
                    profile={this.props.profile}
                    hasBackButton={this.props.backButtonInToolbar}
                    hasHomeButton={this.props.homeButtonInToolbar}
                    hasFavButton={this.props.favButtonInToolbar}
                    hasSearchButton={this.props.searchButtonInToolbar}
                    hasMenuButton={this.props.menuButtonInToolbar}>

                    <MediasPageContent
                        socialMediaConfig={ this.props.socialMediaConfig }
                        twitter={ this.props.twitter }
                        facebook={ this.props.facebook }
                        instagram={ this.props.instagram }
                        youtube={ this.props.youtube }
                        linkedin={ this.props.linkedin }
                        adConfig={ this.props.adConfig }
                        actions={ this.props.actions }
                        labels={ this.props.labels }
                    />

                </AppToolbar>
            </Menu>
        );
    }
};


MediasPage.propTypes = {
    adConfig    : PropTypes.object,
    socialMediaConfig: PropTypes.object.isRequired,
    twitter: PropTypes.object,
    facebook: PropTypes.object,
    instagram: PropTypes.object,
    youtube: PropTypes.object,
    linkedin: PropTypes.object,

    // Common page props
    isMenuOpen        : PropTypes.bool.isRequired,
    profile           : PropTypes.string,
    labels            : PropTypes.object.isRequired,
    actions           : PropTypes.object.isRequired,
    isActive          : PropTypes.func.isRequired,
    setDocumentContext: PropTypes.func.isRequired,
    isLocationEnabled : PropTypes.bool,
    // toolbar
    hasToolbar           : PropTypes.bool,
    homeButtonInToolbar  : PropTypes.bool,
    backButtonInToolbar  : PropTypes.bool,
    searchButtonInToolbar: PropTypes.bool,
    favButtonInToolbar   : PropTypes.bool,
    menuButtonInToolbar  : PropTypes.bool,
}

const mapStateToProps = (state, ownProps) => state[MEDIAS_PAGE_KEY];
const mapDispatchToProps = dispatch => ({ actions: bindActionCreators(actions, dispatch) });

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(MediasPage);
