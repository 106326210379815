import { MEDIAS_PAGE_KEY } from 'src/pages/pagesKeys.js';

import MediasPage        from 'src/pages/medias/MediasPage';
import mediasPageReducer from 'src/pages/medias/mediasPageReducer';

export default {
    key      : MEDIAS_PAGE_KEY,
    path     : '/medias',
    component: MediasPage,
    elId     : 'medias-page',
    getReduxReducer: (state, action) => mediasPageReducer(state[MEDIAS_PAGE_KEY], action),
};
