
import React     from 'react';
import PropTypes from 'prop-types';

import { getUrl } from 'src/core/data-and-assets/DataAssetsUtil';

import './DetailFixedTitle.scss';


/**
 * @return {object}
 */
const getTitleStyle = image => {
    const url = 'url('+getUrl(image)+')';
    return {
        backgroundImage: url,
     };
};


/**
 * @param  {boolean}  isFav
 * @param  {function} onFavClick
 * @param  {object}   labels
 */
const getFavoriteIcon = (isFav, onFavClick, labels) => {
    if (typeof onFavClick !== 'function') {
        console.error('Missing action on click on favorite icon');
        return null;
    }

    let favoriteClasses = [ 'icon-font', 'clickable' ];
    if (isFav === true) {
        favoriteClasses.push('is-fav');
    }

    return (
        <div className={favoriteClasses.join(' ')}
             href="#"
             onClick={onFavClick}
             title={labels.common.addToFavorites}>

            <span className="star">e</span>
        </div>
    );
};


/**
 * Title bar displayed on every 'fiche'
 * (e.g exhibitor, aircraft, event, newproduct, service...)
 *
 * @param  {string}   title
 * @param  {boolean}  isFav        indicate if the item currently belongs to favorites
 * @param  {boolean}  hideFav      indicate if favorite icon should be hidden
 * @param  {function} onFavClick   favorite icon click handler
 * @param  {string}   image        optional: url of an icon to display before title
 * @param  {object}   labels       labels set for current language
 */
const DetailFixedTitle = props => (

    <div className={`prop-name title title-font ${props.children || props.hideFav !== true ? 'title-flex' : ''}`}>
        { typeof props.image === 'string' && <div className="title-image" style={getTitleStyle(props.image)} /> }
        { typeof props.image2 === 'string' && <div className="title-image-small" style={getTitleStyle(props.image2)} /> }
        { typeof props.image3 === 'string' && <div className="title-image-small" style={getTitleStyle(props.image3)} /> }

        <div
            className="prop-desc"
            dangerouslySetInnerHTML={{ __html: props.title }} />

        { props.children }

        { props.hideFav !== true && getFavoriteIcon(props.isFav, props.onFavClick, props.labels) }
    </div>
);


DetailFixedTitle.propTypes = {
    title     : PropTypes.string.isRequired,
    hideFav   : PropTypes.bool,
    isFav     : PropTypes.bool,
    onFavClick: PropTypes.func,
    image     : PropTypes.string,
    image2    : PropTypes.string,
    image3    : PropTypes.string,
    labels    : PropTypes.object,
};

export default DetailFixedTitle;