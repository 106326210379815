
const
    ROUTE_FEAT_ID    = 'map.route',
    LOCATION_FEAT_ID = 'location',
    GEOGROUP_FEAT_ID = 'location.geogroup',
    GEOPUSH_FEAT_ID  = 'location.geopush',
    AROUNDME_FEAT_ID = 'poi.aroundme';


let featuresStatus = {};

export const hasFeatureAroundMe = () => featuresStatus[AROUNDME_FEAT_ID] === true;
export const hasFeatureLocation = () => featuresStatus[LOCATION_FEAT_ID] === true;
export const hasFeatureRoute    = () => featuresStatus[ROUTE_FEAT_ID] === true;
export const hasFeatureGeoGroup = () => featuresStatus[GEOGROUP_FEAT_ID] === true;
export const hasFeatureGeoPush  = () => featuresStatus[GEOPUSH_FEAT_ID] === true;


/**
 * Check if a feature is activated
 * @param {string} featureId
 */
function _check(featureId) {
    window.MobiGeo.hasFeature(featureId, (err, result) => {

        let status;
        if (err) {
            console.warn('Cannot check for '+featureId+' availability', err);
            status = false;
        } else {
            status = result;
        }

        featuresStatus[featureId] = status;
    });
}


export function init() {
    _check(ROUTE_FEAT_ID);
    _check(LOCATION_FEAT_ID);
    _check(GEOGROUP_FEAT_ID);
    _check(GEOPUSH_FEAT_ID);
    _check(AROUNDME_FEAT_ID);

    console.log('MobiGeo features status: ', featuresStatus);
};
