
import { getUrl } from 'src/core/data-and-assets/DataAssetsUtil';
import NotificationLevels from 'src/components-standalone/notifications/NotificationLevels';

/**
 * Handle FACEBOOK success
 * @param  {object} actions   store actions (connected)
 * @param  {object} labels    labels for current lang
 */
function displaySuccess(actions, labels) {
    actions.showNotification({
        message: labels.share.facebookShareDone,
    });
}

/**
 * Handle FACEBOOK error
 * @param  {string} message
 * @param  {object} actions   store actions (connected)
 * @param  {object} labels    labels for current lang
 */
function displayError(message, actions, labels) {
    console.error('Failed to share on facebook', message);

    actions.showNotification({
        message: labels.share.facebookShareFail + (message ? ' ('+message+')' : ''),
        level: NotificationLevels.ERROR,
    });
}

/**
 * Share on FACEBOOK
 *
 * @param  {string} name
 * @param  {string} description
 * @param  {string} url
 * @param  {string} image
 * @param  {object} actions     store actions (connected)
 * @param  {object} labels      labels for current lang
 */
function share(name, description, url, image, actions, labels) {
    if (!window.FB) {
        console.error('Cannot share. Missing Facebook Javascript SDK');
        return;
    }

    window.FB.getLoginStatus(function(response) {
        if (response.status === 'connected') {
            _performShare(name, description, url, image, actions, labels);
        }
        else {
            // Ask user to log in
            window.FB.login(function(response) {
                if (response.status === 'connected') {
                    // Logged into your app and Facebook.
                    _performShare(name, description, url, image, actions, labels);
                } else {
                    // The person is not logged into this app or we are unable to tell.
                    console.warn('Failed to log in to Facebook account');
                }
            });
        }
    });
}
/**
 * Auth is OK, simply call FB share function
 * @see https://developers.facebook.com/docs/sharing/reference/share-dialog
 * @see http://drib.tech/programming/dynamically-change-facebook-open-graph-meta-data-javascript
 */
function _performShare(name, description, url, image, actions, labels) {
    window.FB.ui({
        method: 'share_open_graph',
        action_type: 'og.likes',
        action_properties: JSON.stringify({
            object: {
                'og:url': url,
                'og:title': name,
                'og:description': description,
                'og:image': image ? getUrl(image, true) : null,
            }
        }),
    }, function(response){
        if (response.error_message) {
            displayError(response.error_message, actions, labels);
        } else {
            displaySuccess(actions, labels);
        }
    });
};

export default share;
