import { LIST_GROUPS_PAGE_KEY } from 'src/pages/pagesKeys.js';

import ListGroupsPage        from 'src/pages/list-groups/ListGroupsPage';
import listGroupsPageReducer from 'src/pages/list-groups/listGroupsPageReducer';

export default {
    key      : LIST_GROUPS_PAGE_KEY,
    path     : '/list-groups',
    component: ListGroupsPage,
    elId     : 'list-groups-page',
    getReduxReducer: (state, action) => listGroupsPageReducer(state[LIST_GROUPS_PAGE_KEY], action),
    // NB: `toggleMode` should not be set as 'visibility' because when value is 'none', carousel elements are still visible over other pages
};
