
import { UPDATER_INITIALIZED, CONFIG_JSON_UPDATED } from 'src/store/actionTypes';

import { init } from 'src/core/config-json/ConfigJsonManager';


export default ({ dispatch, getState }) => next => action => {
    const result = next(action);

    switch (action.type) {

        case UPDATER_INITIALIZED:

            // assets versions list is available
            // FALL-THROUGH
            // eslint-disable-line no-fallthrough
        case CONFIG_JSON_UPDATED:
            init();
            break;

        default:
    }
    return result;
};
