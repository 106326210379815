
import React, { Component } from 'react';
import PropTypes            from 'prop-types';
import { List }             from 'react-onsenui';

import Message from 'src/components/inbox/Message';


export default class MessageList extends Component {

    static propTypes = {
        messages: PropTypes.array,
        onClick : PropTypes.func.isRequired,
        lang    : PropTypes.string.isRequired,
        height  : PropTypes.number, // prop set from AppToolbar
    }

    render () {
        const style = {
            height: this.props.height,
            overflowY: 'scroll',
        };

        return (
            <List
                style={style}
                dataSource={this.props.messages}
                renderRow={
                    (message, index) => <Message
                        key={message.id + '-' + index}
                        message={message}
                        onClick={this.props.onClick}
                        lang={this.props.lang}
                    />
                } />
        );
    }

}
