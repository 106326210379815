
import Pages from 'src/pages/Pages';

// import {
//     FAVORITES_PAGE_KEY,
//     LIST_PAGE_KEY,
//     LIST_GROUPS_PAGE_KEY,
//     SEARCH_PAGE_KEY } from 'src/pages/pagesKeys';

import { HAS_NAVIGATED } from 'src/store/actionTypes';

// const PERFORM_FOR_PAGES = [

//     FAVORITES_PAGE_KEY,
//     LIST_PAGE_KEY,
//     LIST_GROUPS_PAGE_KEY,
//     SEARCH_PAGE_KEY,

// ];

const DEFAULT_DELAY = 400;

/**
 * On iOS there is a nasty bug on some pages (search, list, agenda) that makes all the content disappear on touch.
 * Yet you can interact with elements, type text in the input but nothing is visible...
 *
 * No clean fix could be found. A hack is to toggle visibility of the DOM container.
 */

// commented code is guillaume previous' version, now is applied to all the pages. 

function performHack(pageKey, delay=DEFAULT_DELAY) {
    if (Pages[pageKey].mounted === true) {
        let container = Pages[pageKey].getElement();
        if (container) {
            // container.style.visibility = 'hidden';
            container.style.overflow = 'visible';

            window.setTimeout(function() {
                // container.style.visibility = 'visible';
                container.style.overflow = 'hidden';
            }, delay);
        }
    }
}
export default performHack;


export const middleware = ({ dispatch, getState }) => next => action => {
    const result = next(action);

    switch (action.type) {

        case HAS_NAVIGATED:
            // if (PERFORM_FOR_PAGES.indexOf(action.pageKey) !== -1) {
            //     performHack(action.pageKey);
            // }

            performHack(action.pageKey);

            break;

        default: // for linter
    }
    return result;
};
