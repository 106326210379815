

import chooseLangDialogReducer                   from 'src/components-standalone/choose-lang-dialog/langDialogReducer';
import { COMPONENT_KEY as chooseLangDialogKey }  from 'src/components-standalone/choose-lang-dialog/ChooseLangDialog';

import dataListDialogReducer                     from 'src/components-standalone/data-list-dialog/dataListDialogReducer';
import { COMPONENT_KEY as dataListDialogKey }    from 'src/components-standalone/data-list-dialog/DataListDialog';

import disclaimerReducer                         from 'src/components-standalone/disclaimer/disclaimerReducer';
import { COMPONENT_KEY as disclaimerKey }        from 'src/components-standalone/disclaimer/Disclaimer';

import fullLoaderReducer                         from 'src/components-standalone/full-loader/fullLoaderReducer';
import { COMPONENT_KEY as fullLoaderKey }        from 'src/components-standalone/full-loader/FullLoader';

import intersticielReducer                       from 'src/components-standalone/intersticiel/intersticielReducer';
import { COMPONENT_KEY as intersticielKey }      from 'src/components-standalone/intersticiel/Intersticiel';

import loginDialogReducer                        from 'src/components-standalone/login-dialog/loginDialogReducer';
import { COMPONENT_KEY as loginDialogKey }       from 'src/components-standalone/login-dialog/LoginDialog';

import notificationsReducer                      from 'src/components-standalone/notifications/notificationsReducer';
import { COMPONENT_KEY as notificationsKey }     from 'src/components-standalone/notifications/Notifications';

import pollDialogReducer                         from 'src/components-standalone/poll-dialog/pollDialogReducer';
import { COMPONENT_KEY as pollDialogKey }        from 'src/components-standalone/poll-dialog/PollDialog';

import profileDialogReducer                      from 'src/components-standalone/profile-dialog/profileDialogReducer';
import { COMPONENT_KEY as profileDialogKey }     from 'src/components-standalone/profile-dialog/ProfileDialog';

import searchPlaceDialogReducer                  from 'src/components-standalone/search-place-dialog/searchPlaceDialogReducer';
import { COMPONENT_KEY as searchPlaceDialogKey } from 'src/components-standalone/search-place-dialog/SearchPlaceDialog';

import shareDialogReducer                        from 'src/components-standalone/share-dialog/shareDialogReducer';
import { COMPONENT_KEY as shareDialogKey }       from 'src/components-standalone/share-dialog/ShareDialog';


export default [
    { key: chooseLangDialogKey,  reducer: chooseLangDialogReducer },
    { key: dataListDialogKey,    reducer: dataListDialogReducer },
    { key: disclaimerKey,        reducer: disclaimerReducer },
    { key: fullLoaderKey,        reducer: fullLoaderReducer },
    { key: intersticielKey,      reducer: intersticielReducer },
    { key: loginDialogKey,       reducer: loginDialogReducer },
    { key: notificationsKey,     reducer: notificationsReducer },
    { key: pollDialogKey,        reducer: pollDialogReducer },
    { key: profileDialogKey,     reducer: profileDialogReducer },
    { key: searchPlaceDialogKey, reducer: searchPlaceDialogReducer },
    { key: shareDialogKey,       reducer: shareDialogReducer },
];
