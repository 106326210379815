
import {
    LANG_CHANGED,
    DATA_UPDATED,
    DATA_UPDATE_STARTED,
} from 'src/store/actionTypes';

import {
    showFullLoader,
    hideFullLoader
} from 'src/store/actions';


export default ({ dispatch, getState }) => next => action => {
    const result = next(action);
    switch (action.type) {


       case LANG_CHANGED:
            // A bit redundant with DATA_UPDATE_STARTED (as lang change implies data reload).
            // but on old devices there is a delay between these two actions, and loader should be
            // displayed as soon as possible
            // FALL-THROUGH
            // eslint-disable-line no-fallthrough
        case DATA_UPDATE_STARTED:
            dispatch(showFullLoader());
            break;


        case DATA_UPDATED:
            dispatch(hideFullLoader());
            break;


        default:
    }
    return result;
};
