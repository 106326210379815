import { SERVICE_PAGE_KEY } from 'src/pages/pagesKeys.js';

import ServicePage        from 'src/pages/service/ServicePage';
import servicePageReducer from 'src/pages/service/servicePageReducer';

export default {
    key      : SERVICE_PAGE_KEY,
    path     : '/service',
    component: ServicePage,
    elId     : 'service-page',
    getReduxReducer: (state, action) => servicePageReducer(state[SERVICE_PAGE_KEY], action),
};
