
import React     from 'react';
import PropTypes from 'prop-types';


const EmailRow = props => (
    !props.email ? null :
        <div className="free-row">
            <div className="prop-img">
                <div className="prop-left"><span className="fa fa-envelope" style={{fontSize: 20}}/></div>
                <div
                    className="prop-right"
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        wordBreak: 'break-all'
                    }}
                >
                    <a className="link color-link-blue" href={'mailto:'+props.email}>{props.email}</a>
                </div>
            </div>
        </div>
);

EmailRow.propTypes = {
    email: PropTypes.string,
};

export default EmailRow;
