
import Pages from 'src/pages/Pages';
import {
    INBOX_PAGE_KEY,
    MAP_PAGE_KEY,
    HOME_PAGE_KEY
} from 'src/pages/pagesKeys';
import { DATA_TYPE_TO_PAGE_KEY } from 'src/pages/dataToPageMapping';

import { getIdFromOriginalId } from 'src/core/query/Query';
import { openUrl } from 'src/core/util/JsTools';
import { navigate, showOnePoiOnMap } from 'src/store/actions';


export function prefixWith ( prefix ) {
    return str => (prefix + '/' + str).toUpperCase();
}

export function isNonNullObject ( object ) {
    return typeof object === 'object' && object !== null;
}

/**
 * This function uses notification data to determine a resulting redux action
 */
export function resolveAction(action) {

    // Simple and powerful, use router
    if (action.page) {
        let {page, ...props} = action;

        if (props.originalId) {
            // Determine data type
            let dataType = Object.keys(DATA_TYPE_TO_PAGE_KEY).find(dataType => DATA_TYPE_TO_PAGE_KEY[dataType] === page);
            if (!dataType) {
                console.error('Could not determine data type for page '+page);
                return;
            } else {
                // convert originalId to id
                props.id = getIdFromOriginalId(props.originalId, dataType);
            }
        }
        return navigate(page, props);
    }

    const { type } = action;

    // Special case for Map actions
    if (type === 'map') {
        if (typeof action.poi === 'object' && action.poi !== null) {

            // Expected format is: { id: (original_id), type: lower case pluralized }
            // e.g { "type": "map", "poi", { "type": "brands", "id": "1819" }}
            return showOnePoiOnMap({
                type: action.poi.type,
                originalId: action.poi.id,
            });

        } else {
            return navigate(MAP_PAGE_KEY);
        }
    }

    // Inbox case (not a data type)
    else if (type === 'inbox') {
        return navigate(INBOX_PAGE_KEY);
    }

    // home case (not a data type)
    else if (type === 'home') {
        return navigate(HOME_PAGE_KEY, { pollId: action.pollId });
    }

    // Redirect to the page of a specific item
    else if (['number', 'string'].indexOf(typeof action.id) !== -1) {

        const pageKey = DATA_TYPE_TO_PAGE_KEY[type];
        if (!pageKey) {
            throw new Error('Unsupported type: ' + type);
        }
        return navigate(pageKey, { id: Number(getIdFromOriginalId(action.id, type )) });
    }
}


export function playAction(action, dispatch) {

    if (action.type && action.type.match(/https?/ )) {
        openUrl(action.id);
    }
    else {
        dispatch(resolveAction(action));
    }
}


/**
 * @param {String} action - the action field
 *                          of a notification.
 *                          Should be an url
 * action := <string: resource>/<string: id>?params...
 * @param {String} action.type
 * @param {String} action.value
 *
 * @return {Object} parsedAction
 * @param {String} parsedAction.type
 * @param {String?} parsedAction.id
 * @param {Object?} parsedAction.options
 *
 * A parsed action
 */
export function parseAction ( action ) {

    // **Generic and powerful way** using our router
    // `page` corresponds to a page key, and the remaining attributes are the page props.
    // action = { "page": "Exhibitor", "originalId": "969" }`
    if (action && action.page && Pages[action.page]) {
        return action;

    } else if ( typeof action !== 'string' ) {
        return {
            type: 'inbox'
        };

    } else if ( action.match(/^https?/) !== null ) {
        return {
            type: 'http',
            id: action
        };

    } else {
        const getParams = action.split('?')[1];
        const terms = action.split('?')[0].split('/');

        const _action = terms
            .filter(
                term => typeof term === 'string' && term.length > 0
            )
            .reduce((action, term, index) => {
                        switch( index ) {
                            case 0:
                                action.type = term;
                                return action;
                            case 1:
                                action.id = term;
                                return action;
                            default:
                                throw new Error('Too many terms in action');
                        }
                    },
                    {}
                );

        if (typeof getParams === 'string' && getParams.length > 0) {

            _action.options = getParams.split('&')
                .reduce(
                    (options, item) => {
                        const [ key, value ] = item.split('=');
                        options[decodeURIComponent(key)] = decodeURIComponent(value);
                        return options;
                    },
                    {}
                );
        }

        if (Object.keys(_action).length === 0) {
            return {
                type: 'inbox'
            };
        }
        return _action;
    }
}
