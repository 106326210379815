

import { latinise } from 'src/core/util/StringUtil';

let naturalSort = require('javascript-natural-sort');


/**
 * Sort 2 strings
 * (NB: Using natural-sort fixes this: "Boutique 1", "Boutique 10", "Boutique 11", "Boutique 12", "Boutique 2")
 * @param  {string} strA
 * @param  {string} strB
 * @return {number} -1 if strA is before strB  /  1 if strB is before strA  /  0 if strings are the same
 */
export function sortStrings(strA, strB) {

    // Fix sort issue ('États-unis' considered after 'Ukraine')
    let sA = latinise(String(strA).toUpperCase()),
        sB = latinise(String(strB).toUpperCase());

    return [sA, sB].sort(naturalSort).indexOf(sA) === 0 ? -1 : 1;

    // s1.localeCompare(s2, getCurrentLang());
};


/**
 * Simplest sort
 * @param  {*} tsA
 * @param  {*} tsB
 * @return {number}
 */
export function simpleSort(tsA, tsB) {
    if (tsA > tsB) {
        return 1;
    }
    else if (tsA < tsB) {
        return -1;
    }
    return 0;
};

/**
 * Sort by stringified hour, e.g sort '17h15' and '08h30'
 * @param  {string} str1
 * @param  {string} str2
 * @return {number}
 */
export function sortByStringifiedHour(str1, str2) {
    let hourA = parseInt(str1.slice(0, 2), 10),
        hourB = parseInt(str2.slice(0, 2), 10);

    if (hourA > hourB) {
        return 1;
    }
    else if (hourA < hourB) {
        return -1;
    }
    else {
        // if hour are equal, sort by minutes
        let minA  = parseInt(str1.slice(3, 5), 10),
            minB  = parseInt(str2.slice(3, 5), 10);

        if (minA > minB) {
            return 1;
        }
        else if (minA < minB) {
            return -1;
        }
        return 0;
    }
};