
// Libs
import { createStore, applyMiddleware, bindActionCreators } from 'redux';
import createLogger    from 'redux-logger';
import thunkMiddleware from 'redux-thunk';

import * as _actions from './actions';

// Config
import config from 'data/config/config';

// Middlewares
import configJsonMiddleware   from 'src/core/config-json/configJsonMiddleware';
import pollManagerMiddleware from 'src/core/polls/pollManagerMiddleware';
import contributionsFeedManagerMiddleware from 'src/core/contributions-feed/contributionsFeedManagerMiddleware';
import analyticsMiddleware    from 'src/core/analytics/analyticsMiddleware';
import bootMiddleware         from 'src/core/bootMiddleware';
import dataAssetsMiddleware   from 'src/core/data-and-assets/dataAssetsMiddleware';
import fullLoaderMiddleware   from 'src/components-standalone/full-loader/fullLoaderMiddleware';
import inboxMiddleware        from 'src/pages/inbox/inboxMiddleware';
import intersticielMiddleware from 'src/components-standalone/intersticiel/intersticielMiddleware';
import mapMiddleware          from 'src/pages/map/mapMiddleware';
import searchPlaceDialogMiddleware from 'src/components-standalone/search-place-dialog/searchPlaceDialogMiddleware';
import routerMiddleware       from 'src/core/navigation/routerMiddleware';
import loginMiddleware        from 'src/core/login/LoginMiddleware';
import profileMiddleware      from 'src/core/profile/profileMiddleware';
import socialMiddleware      from 'src/core/social/socialMiddleware';
import appointmentRequestMiddleware from 'src/core/appointment-request/appointmentRequestMiddleware';
import synchronizedFavoritesMiddleware from 'src/core/favorites/synchronizedFavoritesMiddleware';
import { middleware as flightScheduleMiddleware } from 'src/pages/flights_schedule/flightsReducer';
import { middleware as iosWhitePageHackMiddleware } from 'src/core/util/iosWhitePageHack';

// App modules
import * as ConfigJson               from 'src/core/config-json/ConfigJsonManager';
import * as Db                       from 'src/core/data-and-assets/Db';
import * as PollManager              from 'src/core/polls/PollManager';
import * as PollService              from 'src/core/polls/PollService';
import * as ContributionsFeedManager from 'src/core/contributions-feed/ContributionsFeedManager';
import * as ContributionsFeedService from 'src/core/contributions-feed/ContributionsFeedService';

import * as AppointmentRequestService from 'src/core/appointment-request/AppointmentRequestService';
import * as ConfigItemFactory         from 'src/core/util/ConfigItemFactory';
import * as Cordova                   from 'src/core/cordova/cordovaReducerAndMiddleware';
import * as GoogleAnalyticsHelper     from 'src/core/analytics/GoogleAnalyticsHelper';
import * as LoginService              from 'src/core/login/LoginService';
import * as Profile                   from 'src/core/Profile';
import * as ProfileService            from 'src/core/profile/ProfileService';
import * as SocialService             from 'src/core/social/SocialService';
import * as RealTimeService           from 'src/core/realtime/RealTimeService';
import * as ResizeHandler             from 'src/core/util/ResizeHandler';
import * as Router                    from 'src/core/navigation/Router';
import * as SynchronizedFavoritesService from 'src/core/favorites/SynchronizedFavoritesService';
import * as Updater                   from 'src/core/data-and-assets/Updater';

import { isIOS } from 'src/core/util/browser';

import rootReducer from './reducers';


/**
 * Give a `store` reference to not connected modules
 * @param  {object} store
 * @param  {object} actions (binded)
 */

function giveStoreReferenceToNonReactModules(store, actions) {

    // Provide store binded actions
    ConfigJson.setBindedActions(actions);
    Db.setBindedActions(actions);
    Updater.setBindedActions(actions);
    Profile.setBindedActions(actions);
    ResizeHandler.setBindedActions(actions);
    ConfigItemFactory.setBindedActions(actions);
    RealTimeService.setBindedActions(actions);
    ProfileService.setBindedActions(actions);
    ContributionsFeedManager.setBindedActions(actions);

    if (ContributionsFeedManager.isEnabled()) {
        ContributionsFeedService.setBindedActions(actions);
    }
    if (config.POLL.FEATURE_ENABLED) {
        PollService.setBindedActions(actions);
    }
    if (config.LOGIN.FEATURE_ENABLED) {
        LoginService.setBindedActions(actions);
    }
    if (config.APPOINTMENT_REQUEST.FEATURE_ENABLED) {
        AppointmentRequestService.setBindedActions(actions);
    }
    if (config.SYNCHRONIZED_FAVORITES.FEATURE_ENABLED) {
        SynchronizedFavoritesService.setBindedActions(actions);
    }

    // Provide store
    Router.setReduxStore(store);
    PollManager.setReduxStore(store);
    SocialService.setReduxStore(store);
}


export default function() {

    // Declare store middlewares
    let middlewares = [ thunkMiddleware ];

    if (config.ENV === 'dev') {
        middlewares.push(createLogger);
    }
    middlewares.push(routerMiddleware);
    middlewares.push(dataAssetsMiddleware);
    middlewares.push(intersticielMiddleware);
    middlewares.push(bootMiddleware);
    middlewares.push(fullLoaderMiddleware);
    middlewares.push(configJsonMiddleware);
    middlewares.push(searchPlaceDialogMiddleware);
    middlewares.push(mapMiddleware);
    middlewares.push(inboxMiddleware);
    middlewares.push(Cordova.createMiddleware(global.isCordovaContext ? cordova : null));
    middlewares.push(profileMiddleware);

    if (/*global.isCordovaContext && */isIOS()) {
        middlewares.push(iosWhitePageHackMiddleware);
    }
    if (config.SOCIAL.FEATURE_ENABLED) {
        middlewares.push(socialMiddleware);
    }
    if (config.POLL.FEATURE_ENABLED) {
        middlewares.push(pollManagerMiddleware);
    }
    if (config.FLIGHTS_SCHEDULE.FEATURE_ENABLED) {
        middlewares.push(flightScheduleMiddleware);
    }
    if (config.LOGIN.FEATURE_ENABLED) {
        middlewares.push(loginMiddleware);
    }
    if (config.APPOINTMENT_REQUEST.FEATURE_ENABLED === true) {
        middlewares.push(appointmentRequestMiddleware);
    }
    if (config.SYNCHRONIZED_FAVORITES.FEATURE_ENABLED === true) {
        middlewares.push(synchronizedFavoritesMiddleware);
    }
    if (GoogleAnalyticsHelper.isEnabled()) {
        middlewares.push(analyticsMiddleware);
    }
    if (ContributionsFeedManager.isEnabled()) {
        middlewares.push(contributionsFeedManagerMiddleware);
    }

    // Create store
    let store = createStore(
        rootReducer,
        applyMiddleware( ...middlewares )
    );

    const actions = bindActionCreators(_actions, store.dispatch);

    giveStoreReferenceToNonReactModules(store, actions);

    return {
        store,
        actions,
    };
}
