
// Libs
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

// Conf
import { getSortConfig } from 'data/config/sortConfig';

// App modules
import List from 'src/components/list/List';

import './DetailDataList.scss';


/**
 * Common component to display related newproducts
 * @param {array}  items
 * @param {object} labels
 * @param {object} store connected actions
 */
class DetailDataList extends PureComponent {

    render() {
        let length = Array.isArray(this.props.items) ? this.props.items.length : 0;

        // Skip component display
        if (length === 0) {
            return null;
        }

        const dataType = this.props.dataType
        const separatorLabel = this.props.labels.data[dataType][length > 1 ? 'plural' : 'singular']
        const sortFunction = getSortConfig()[dataType]
        const items = sortFunction && sortFunction.sortFunction ? this.props.items.sort(sortFunction.sortFunction) : this.props.items

        return (
            <div className="detail-newproducts-component">
                <li className="fiche-separator">{separatorLabel}</li>
                <div className="fiche-related-data">
                    <List
                        isPending={false}
                        dataType={dataType}
                        actions={this.props.actions}
                        labels={this.props.labels}
                        items={items}
                        displayFavorites={false}
                        pageKey={this.props.pageKey} />
                </div>
            </div>
        );
    }
};

DetailDataList.propTypes = {
    dataType: PropTypes.string.isRequired,
    items   : PropTypes.array,
    labels  : PropTypes.object.isRequired,
    actions : PropTypes.object.isRequired,
    pageKey : PropTypes.string.isRequired,
};

export default DetailDataList;
