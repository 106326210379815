import {
	getPollConfig,
	getPollConfigById,
	getLastPollStep,
    getNextPollStep,
    getPreviousPollStep,
	getFirstPollStep
} from 'src/core/polls/PollManager';

import {
    SHOW_POLL_DIALOG,
    HIDE_POLL_DIALOG,
    PROFILE_CHANGED,
  	POLL_CONFIG_LOADED,
    LANG_CHANGED,
  	SET_POLL_ID,
	SET_POLL_CODE,
  	GO_TO_NEXT_POLL_STEP,
  	GO_TO_PREVIOUS_POLL_STEP,
  	SET_POLL_ERROR,
  	SET_POLL_PAGE,
  	SET_POLL_MARK,
  	SET_POLL_COMMENT,
  	SET_POLL_CHOICE,
	SET_POLL_MULTIPLE,
    WINDOW_RESIZED,
    SUBMIT_POLL
} from 'src/store/actionTypes';

import {
    get as getLabels,
	   getCurrent as getLang
} from 'src/core/Lang';

import { get as getProfile } from 'src/core/Profile';

import {
    langChanged,
    updateObject,
    windowResized
} from 'src/store/reducers/commons';

const getDefaultState = () => ({
    pollConfig: getPollConfig(getProfile()) || null,
    profile: getProfile(),
    labels: getLabels(),
    lang: getLang(),
    isOpen: false,
    page: 'form',
	poll_id: null,
    poll_code: null,
    poll_topic: null,
    poll_intro_title: null,
    poll_open_date: null,
    poll_close_date: null,
    poll_close_text: null,
    poll_close_label: null,
    poll_close_link: null,
    isPolling: false,
    config: null,
    data: null,
    mark: 0,
    choice: '',
    comment: '',
    error: null
});

const _toggleDialog = (state, action, value) => {
    let newValues = {
		isOpen: value
	}

	if (value && action.poll_id) {
		const poll = getPollConfigById(action.poll_id)

		if (poll) {
			const pollInfo = {
				poll_intro_title: poll.poll_intro_title,
				poll_topic: poll.poll_topic,
				poll_open_date: poll.poll_open_date,
				poll_close_date: poll.poll_close_date,
	            poll_close_text: poll.poll_close_text,
	            poll_close_link: poll.poll_close_link,
	            poll_close_label: poll.poll_close_label,
				poll_id: poll.poll_id
			}
			const step = getFirstPollStep(action.poll_id) || {}
			return updateObject(state, {
				...newValues,
				...step,
				...pollInfo
			})
		}

		return updateObject(state, {
			...getDefaultState()
		});
	}

	// reset state
	if (!value) {
		return updateObject(state, {
			poll_id: action.pollId,
			...getDefaultState()
		});
	}

    return updateObject(state, {
		...newValues,
		poll_id: null
	});
};

function _profileChanged(state, action) {
    // On app initialization, lang file is not loaded yet
    if (getLabels()) {
        const pollConfig = getPollConfig(action.profile);

        return updateObject(state, {
            profile   : action.profile,
            pollConfig: pollConfig
        });
    }
    return state;
}

function _pollConfigLoaded(state, action) {
    let pollConfig = getPollConfig(state.profile);
    return updateObject(state, {
        pollConfig
    });
}

const _setPollId = (state, action) => {
	const poll = getPollConfigById(action.poll_id)
	const pollInfo = {
		poll_intro_title: poll.poll_intro_title,
		poll_topic: poll.poll_topic,
		poll_open_date: poll.poll_open_date,
		poll_close_date: poll.poll_close_date,
        poll_close_text: poll.poll_close_text,
        poll_close_link: poll.poll_close_link,
        poll_close_label: poll.poll_close_label,
		poll_id: poll.poll_id
	}
	const step = getFirstPollStep(action.poll_id) || {}
	return updateObject(state, {
		...step,
		...pollInfo
	})
}

const _setPollCode = (state, action) => updateObject(state, {
	poll_code: action.poll_code
})

const _goToNextPollStep = (state, action) => {
	const step = getNextPollStep(state.poll_id, action.question_id)
	if (step.data || step.config) return updateObject(state, { ...step })
	return state
}

const _goToPreviousPollStep = (state, action) => {
	let step = getPreviousPollStep(state.poll_id, action.question_id)
	if (!action.question_id) {
		step = getLastPollStep(state.poll_id)
	}
	if (step.data || step.config) return updateObject(state, { ...step })
	return state
}

const _setPollError = (state, action) => (
    updateObject(state, { error: action.error, isPolling: false })
);

const _setPollPage = (state, action) => (
	updateObject(state, { page: action.page, isPolling: false })
)

const _setPollMark = (state, action) => (
    updateObject(state, { mark: action.value })
);

const _setPollComment = (state, action) => (
    updateObject(state, { comment: action.value })
);

const _setPollChoice = (state, action) => (
    updateObject(state, { choice: action.value })
);

const _setPollMultiple = (state, action) => (
    updateObject(state, { multiple: action.value })
);

const _submitPoll = (state, action) => (
    updateObject(state, { isPolling: true })
);


export default (state = getDefaultState(), action) => {
    switch (action.type) {

        case LANG_CHANGED: return langChanged(state, action);
		case POLL_CONFIG_LOADED : return _pollConfigLoaded(state, action);
		case SET_POLL_ID: return _setPollId(state, action);
		case SET_POLL_CODE: return _setPollCode(state, action);
		case GO_TO_NEXT_POLL_STEP: return _goToNextPollStep(state, action);
		case GO_TO_PREVIOUS_POLL_STEP: return _goToPreviousPollStep(state, action);
        case SHOW_POLL_DIALOG: return _toggleDialog(state, action, true);
        case HIDE_POLL_DIALOG: return _toggleDialog(state, action, false);
        case PROFILE_CHANGED: return _profileChanged(state, action);
		case SET_POLL_ERROR: return _setPollError(state, action);
		case SET_POLL_PAGE: return _setPollPage(state, action);
		case SET_POLL_MARK: return _setPollMark(state, action);
		case SET_POLL_COMMENT: return _setPollComment(state, action);
		case SET_POLL_CHOICE: return _setPollChoice(state, action);
		case SET_POLL_MULTIPLE: return _setPollMultiple(state, action);
        case SUBMIT_POLL: return _submitPoll(state, action);
        case WINDOW_RESIZED   : return windowResized(state, action);

        default: return state;
    }
};
