
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import Loader   from 'src/components/loader/Loader';
import NoResult from 'src/components/no-result/NoResult';


/**
 * Simple wrapper to handle fetch result and reduce boilerplate and status props transmission and control
 */
class FetchResult extends PureComponent {

    onContentRef = ref => {
        if (typeof this.props.contentRef === 'function') {
            this.props.contentRef(ref);
        }
    }

    render() {
        let { Component, isPending, itemNotFound, item, labels, actions, ...componentProps } = this.props;

        if (!item) {

            // No result
            if (itemNotFound === true) {
                return <NoResult labels={labels} />;

            // Pending request
            } else if (isPending === true) {
                return <Loader labels={labels} />;
            }

            console.error('Unexpected state (no item, and request is neither pending nor without result)');
            return null;
        }

        return (
            <Component
                ref={this.onContentRef}
                item={item}
                labels={labels}
                actions={actions}
                {...componentProps} />
        );
    }
}

FetchResult.propTypes = {
    Component   : PropTypes.func.isRequired,
    contentRef  : PropTypes.func,
    isPending   : PropTypes.bool,
    itemNotFound: PropTypes.bool,
    item        : PropTypes.object,
    labels      : PropTypes.object.isRequired,
    actions     : PropTypes.object.isRequired
};

export default FetchResult;
