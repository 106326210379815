import { HOME_PAGE_KEY } from 'src/pages/pagesKeys.js';

import HomePage        from 'src/pages/home/HomePage';
import homePageReducer from 'src/pages/home/homePageReducer';

export default {
    key      : HOME_PAGE_KEY,
    path     : '/home',
    component: HomePage,
    elId     : 'home-page',
    getReduxReducer: (state, action) => homePageReducer(
        state[HOME_PAGE_KEY],
        action
    ),
    toggleMode  : 'visibility', // NB: if HomePage `toggleMode` is set to display:none Masonry doesn't work great
};
