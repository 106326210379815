
import { DATA_TYPE_EVENTS, DATA_TYPE_SPEAKERS }  from 'data/config/dataConfig';

import { AUTOSHOW_POI_ON_MAP } from 'data/config/pagesTwoColumnsConfig';

import {
    CONFIG_JSON_LOADED,
    POLL_CONFIG_LOADED,
    ALL_FAVORITES_DELETED,
    DATA_UPDATED,
    ITEM_FETCHED,
    LANG_CHANGED,
    PROFILE_CHANGED,
    TOGGLE_FAVORITE,
    TOGGLE_LOCATION_STATUS,
    TOGGLE_MENU,
    UPDATE_PAGE_STATE,
    CONTRIBUTIONS_FEED_LOADED,
    ACTIVATE_CONTRIBUTIONS_REFRESH
} from 'src/store/actionTypes';

import {
    configJsonLoaded,
    pollConfigLoaded,
    dataUpdated,
    getPageCommonDefaultState,
    itemFetched,
    langChanged,
    profileChanged,
    setIsFavoriteFalse,
    toggleFavorite,
    toggleLocationStatus,
    toggleMenu,
    updatePageState,
    updateObject
} from 'src/store/reducers/commons';

import { EVENT_PAGE_KEY } from 'src/pages/pagesKeys';

import { persistence as contributionsPersistence } from 'src/core/contributions-feed/ContributionsFeedService';

const USED_TABLES = [ DATA_TYPE_EVENTS, DATA_TYPE_SPEAKERS ];

const getDefaultState = () => Object.assign({}, getPageCommonDefaultState(EVENT_PAGE_KEY), {
    isPending: true,
    autoShowPoi: AUTOSHOW_POI_ON_MAP[EVENT_PAGE_KEY] === true,
    activateRefresh: true
});

const _getEventContrib = (state) => {
    const contributions = contributionsPersistence.get();

    if (state.item && state.item.lump && state.item.lump.rt_code) {

        if (contributions
            && contributions.value
            && contributions.value.sc_code) {

            const rt_code = state.item.lump.rt_code
            const eventContrib = contributions.value.sc_code[rt_code]
            const contribDate = contributions.value.current_time

            if (eventContrib) {
                return updateObject(state, {
                    contributions: {
                        date: contribDate,
                        events: eventContrib
                    }
                });
            }
        }

        return updateObject(state, { contributions: { empty: true } })
    }

    return updateObject(state, { contributions: null })
}

const _itemFetched = (state, action) => {
    const updatedState = itemFetched(state, action, DATA_TYPE_EVENTS);
    return _getEventContrib(updatedState)
}

const _contributionsFeedLoaded = (state, action) => {
    return _getEventContrib(state, action.payload)
}

const _activateContributionsRefresh = (state, action) => {
    return updateObject(state, { activateRefresh: action.activate });
}

export default (state = getDefaultState(), action) => {
    switch (action.type) {

        case CONFIG_JSON_LOADED: return configJsonLoaded(state);
        case POLL_CONFIG_LOADED : return pollConfigLoaded(state, action);
        case ALL_FAVORITES_DELETED: return setIsFavoriteFalse(state);
        case DATA_UPDATED: return dataUpdated(state, action, USED_TABLES);
        case ITEM_FETCHED: return _itemFetched(state, action);
        case LANG_CHANGED: return langChanged(state, action);
        case PROFILE_CHANGED: return profileChanged(state, action, EVENT_PAGE_KEY);
        case TOGGLE_FAVORITE: return toggleFavorite(state, action, DATA_TYPE_EVENTS);
        case TOGGLE_LOCATION_STATUS: return toggleLocationStatus(state, action);
        case TOGGLE_MENU: return toggleMenu(state, action, EVENT_PAGE_KEY);
        case UPDATE_PAGE_STATE: return updatePageState(state, action, EVENT_PAGE_KEY);
        case CONTRIBUTIONS_FEED_LOADED: return _contributionsFeedLoaded(state, action);
        case ACTIVATE_CONTRIBUTIONS_REFRESH: return _activateContributionsRefresh(state, action);

        default: return state;
    }
};
