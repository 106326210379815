
import config from 'data/config/config';
import * as PushWooshManager from 'src/core/PushWooshManager';

import { getParameterByName } from 'src/core/util/JsTools';


const LOG_PREF = '[Profile] ';

const QUERY_PARAMETER_KEY = 'profile';
const PROFILE_LOCALSTORAGE_KEY = 'profile';


let actions;
export function setBindedActions(_actions) {
    actions = _actions;
};


/**
 * Get the current profile
 * @return {string}
 */
export const get = () => localStorage.getItem(PROFILE_LOCALSTORAGE_KEY);


/**
 * Set the current profile
 * @param  {string} value
 */
export function set(value) {
    if (typeof value === 'string') {

        // Check argument value
        if (config.SUPPORTED_PROFILES.indexOf(value) === -1) {
            console.error(LOG_PREF+'Unsupported profile `'+value+'`, accepted values are ', config.SUPPORTED_PROFILES);
            //value = config.DEFAULT_PROFILE;
        }

        // Ignore if value is the same
        if (get() !== value) {
            localStorage.setItem(PROFILE_LOCALSTORAGE_KEY, value);
            PushWooshManager.setProfileTag(value);
            if (actions) {
                actions.profileChanged(value);
            }
        }
    }
};


/**
 * Indicate if a (valid) profile has been set
 * @return {boolean}
 */
export function checkProfile() {
    // If there is only one profile, set as default
    if (config.SUPPORTED_PROFILES.length === 1) {
        set(config.SUPPORTED_PROFILES[0]);
        return true;
    }
    else {
        return config.SUPPORTED_PROFILES.indexOf(get()) !== -1;
    }
};


/**
 * Initialize by setting current profile
 */
export function init() {
    // Read value from query string, or localstorage, or use default
    const queryValue = getParameterByName(QUERY_PARAMETER_KEY);

    let profile = queryValue || get() || config.DEFAULT_PROFILE;

    set(profile);
    console.log(LOG_PREF+'value: '+get());
};
