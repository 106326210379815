
// Simple module avoiding a circular dependency
// data/config/pagesConfig.js
//   -> src/pages/map/MapPage.js
//     -> src/pages/map/mapMiddleware.js
//       -> src/core/navigation/Router.js
//         -> src/pages/Pages.js -> data/config/pagesConfig.js

let loaded = false;

export default {
    setLoaded: value => { loaded = value; },
    isLoaded : value => loaded === true,
};
