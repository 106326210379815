
// Libs
import React, { Component }   from 'react';
import PropTypes              from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect }            from 'react-redux';

// Config
import { FAVORITES_PAGE_KEY } from 'src/pages/pagesKeys';

// App modules
import AppToolbar       from 'src/components/app-toolbar/AppToolbar';
import Menu             from 'src/components/menu/Menu';
import FavoritesContent from './FavoritesContent';

import * as actions from 'src/store/actions';

import './FavoritesPage.scss';


const LOG_PREF = '[FavoritesPage] ';


class FavoritesPage extends Component {

    constructor(props) {
        super(props);
        this.pageKey = FAVORITES_PAGE_KEY;
    }

    /**
     * Update string visible in browser tab/history/favorites
     */
    setDocumentContext() {
        if (this.props.isActive(this.pageKey)) {
            this.props.setDocumentContext(this.props.labels.favorites.title);
        }
    }

    /**
     * String displayed in app toolbar
     * @return {string}
     */
    getPageTitle() {
        return this.props.labels.favorites.title;
    }

    componentDidMount() {
        this.props.actions.fetchFavorites();
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.shouldFetch === true) {
            this.props.actions.fetchFavorites();
        }
    }

    componentDidUpdate() {
        this.setDocumentContext();
    }

    render() {
        console.log(LOG_PREF+'render');
        return (
            <Menu
                options={{ isOpen: this.props.isMenuOpen }}
                actions={this.props.actions}
                labels={this.props.labels}
                profile={this.props.profile}
                associatedPageKey={this.pageKey}
                adConfig={this.props.adConfig}
                twoColumns={this.props.twocolumns}
                isLocationEnabled={this.props.isLocationEnabled}>

                <AppToolbar
                    labels={this.props.labels}
                    isDisplayed={this.props.hasToolbar}
                    actions={this.props.actions}
                    title={this.getPageTitle(this.props)}
                    pageKey={this.pageKey}
                    profile={this.props.profile}
                    synchroFavBtnStatus={this.props.synchroFavBtnStatus}
                    isSynchroFavFeatureEnabled={this.props.isSynchroFavFeatureEnabled}
                    hasBackButton={this.props.backButtonInToolbar}
                    hasHomeButton={this.props.homeButtonInToolbar}
                    hasFavButton={this.props.favButtonInToolbar}
                    hasSearchButton={this.props.searchButtonInToolbar}
                    hasMenuButton={this.props.menuButtonInToolbar}>

                    <FavoritesContent
                        actions={this.props.actions}
                        data={this.props.data}
                        favorites={this.props.favorites}
                        labels={this.props.labels}
                        isPending={this.props.isPending}
                        itemNotFound={this.props.itemNotFound}
                        isSynchroFavFeatureEnabled={this.props.isSynchroFavFeatureEnabled}
                        profile={this.props.profile}
                        isLoggedIn={this.props.isLoggedIn}
                        code={this.props.code}
                        codeSyncStep={this.props.codeSyncStep}
                        isCodeDialogOpen={this.props.isCodeDialogOpen}
                        synchroFavBtnStatus={this.props.synchroFavBtnStatus}
                        temporaryCode={this.props.temporaryCode}/>
                </AppToolbar>
            </Menu>
        );
    }
};

FavoritesPage.propTypes = {
    data        : PropTypes.object,
    favorites   : PropTypes.object,
    isPending   : PropTypes.bool,
    itemNotFound: PropTypes.bool,
    isSynchroFavFeatureEnabled: PropTypes.bool,
    isLoggedIn : PropTypes.bool,
    // Common page props
    isMenuOpen        : PropTypes.bool.isRequired,
    profile           : PropTypes.string,
    labels            : PropTypes.object.isRequired,
    actions           : PropTypes.object.isRequired,
    isActive          : PropTypes.func.isRequired,
    queryString       : PropTypes.string.isRequired,
    setDocumentContext: PropTypes.func.isRequired,
    isLocationEnabled : PropTypes.bool,
    // toolbar
    hasToolbar           : PropTypes.bool,
    synchroFavBtnStatus  : PropTypes.string,
    homeButtonInToolbar  : PropTypes.bool,
    backButtonInToolbar  : PropTypes.bool,
    searchButtonInToolbar: PropTypes.bool,
    favButtonInToolbar   : PropTypes.bool,
    menuButtonInToolbar  : PropTypes.bool,
};

const mapStateToProps = (state, ownProps) => state[FAVORITES_PAGE_KEY];
const mapDispatchToProps = dispatch => ({ actions: bindActionCreators(actions, dispatch) });

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(FavoritesPage);
