import moment from 'moment';

import config from 'data/config/config';

import fetchHelper from 'src/core/util/FetchHelper';

import {
	setSocialFeedError,
    socialFeedLoaded
} from 'src/store/actions';

import {
	MEDIAS_PAGE_KEY
} from 'src/pages/pagesKeys';

export const LOG_PREF = '[SocialService]';

export const ERRORS = {
    NETWORK: 'SOCIAL_NETWORK_ERROR',
    SERVER: 'SOCIAL_SERVER_ERROR'
};

const LOCALSTORAGE_KEY = 'social_feed';

let dispatch;
let storeRef;
export function setReduxStore(store) {
    dispatch = store.dispatch;
    storeRef = store
}

const isEnabled = () => config.SOCIAL.FEATURE_ENABLED

/**
 * Sub-mobule responsible for persistence
 * @type {Object}
 */
export const persistence = {
    get: function(){
        let value = localStorage.getItem(LOCALSTORAGE_KEY)
        return value ? JSON.parse(value) : null;
    },
    set: function(value){
        const timestamp = moment().unix()
        const data = {
            date: timestamp,
            value
        }

        localStorage.setItem(LOCALSTORAGE_KEY, JSON.stringify(data));
    },
};

/**
 * Call the API to get social feed data
 * @param  {object}   data
 */
export function fetch(payload) {
    if (isEnabled()) {
        const opts = {
            method: 'GET',
        };

        const cache = persistence.get()
        const now = moment().unix()
        const lastRecordedAt = cache ? Number(cache.date) : null

        if (!lastRecordedAt || (now - lastRecordedAt > 2)) {
            console.log(LOG_PREF+'Fetching social feed at '+config.SOCIAL.URL)

            const state = storeRef.getState()
        	const mediasPageState = state[MEDIAS_PAGE_KEY]
            const type = payload.type
            const socialId = mediasPageState.socialMediaConfig[type].socialId
            const sinceId = payload.sinceId
            const maxId = payload.maxId

            console.log('type', type)

            const limit = config.SOCIAL
                && config.SOCIAL[type.toUpperCase()]
                && config.SOCIAL[type.toUpperCase()].POST_PER_PAGE ? config.SOCIAL[type.toUpperCase()].POST_PER_PAGE : payload.limit

            let fetchUrl = `${config.SOCIAL.URL}?socialId=${encodeURIComponent(socialId)}&type=${type}`

            if (sinceId) {
                fetchUrl = `${fetchUrl}&sinceId=${sinceId}`
            }
            else if (maxId) {
                fetchUrl = `${fetchUrl}&maxId=${maxId}`
            }

            if (limit) {
                fetchUrl = `${fetchUrl}&limit=${limit}`
            }

            fetchUrl = encodeURI(fetchUrl)

            fetchHelper(
                fetchUrl,
                opts,
                true,
                data => _onFetchSuccess(type, data, payload, payload.callback),
                error => _onFetchFailure(error, payload.callback),
                false
            );
        } else {
            console.log('--------------------->  Last fetched was done less than 2s ago ....')
            if (payload.callback && typeof payload.callback === 'function') {
                payload.callback()
            }
        }
    }
}

function _onFetchSuccess(type, data, initialPayload, cb) {
    dispatch(socialFeedLoaded(type, data.posts, initialPayload))

    if (cb && typeof cb === 'function') {
        cb()
    }
    // setTimeout(() => dispatch(activateSocialFeedRefresh(true)), 10 * 1000)
}

function _onFetchFailure(err, cb) {
    dispatch(setSocialFeedError(ERRORS.NETWORK))

    if (cb && typeof cb === 'function') {
        cb()
    }
}
