import { EXHIBITOR_PAGE_KEY } from 'src/pages/pagesKeys.js';

import ExhibitorPage        from 'src/pages/exhibitor/ExhibitorPage';
import exhibitorPageReducer from 'src/pages/exhibitor/exhibitorPageReducer';

export default {
    key      : EXHIBITOR_PAGE_KEY,
    path     : '/exhibitor',
    component: ExhibitorPage,
    elId     : 'exhibitor-page',
    getReduxReducer: (state, action) => exhibitorPageReducer(state[EXHIBITOR_PAGE_KEY], action),
};
