import { getParameterByName } from 'src/core/util/JsTools';

import {
    HAS_NAVIGATED,
    POLL_CONFIG_UPDATED,
    SET_POLL_STEP,
    // VALIDATE_POLL,
    VALIDATE_POLL_CODE,
    SHOW_POLL_DIALOG,
    SUBMIT_POLL,
    UPDATER_INITIALIZED,
    GO_TO_PREVIOUS_POLL_STEP,
    SET_POLL_ID
} from 'src/store/actionTypes';

import {
    init,
    setPollStep,
    getPollData,
    isPollIdValid,
    isPollProtected,
    isPollValid,
    QUERY_PARAMETER_KEY
} from 'src/core/polls/PollManager';

import {
    submit,
    showDialog,
    setError,
    setPage,
    setCode,
    ERRORS,
    ERROR_MANAGEMENT_TABLE
} from 'src/core/polls/PollService';

import { COMPONENT_KEY } from 'src/components-standalone/poll-dialog/PollDialog';

const _validatePoll = (poll_id, poll_code, context) => {
    const validateFunctions = [
        isPollIdValid,
        isPollProtected,
        isPollValid
    ]

    let errorStore = null

    const pollValid = validateFunctions.every(validateCb => {
        const { valid, error, storeCode } = validateCb(poll_id, poll_code)

        // if code valid store code to avoid subsequent code related errors
        if (poll_code && storeCode) setCode(poll_code)

        if (!valid) {
            setError(error)
            ERROR_MANAGEMENT_TABLE[error](context)
            errorStore = error
            return false
        }
        return true
    })

    if (pollValid) {
        setError(null)
        return { error: false }
    }
    return { error: errorStore }
}

const _parseAnswers = (data, profile) => {
    const payload = { ...data }
    const pollId = payload.poll_id
    payload.pollId = pollId
    delete payload.poll_id
    delete payload.poll_code
    if (payload.answers && payload.answers.length > 0) {
        payload.answers = payload.answers.map((answer = {}) => {
            let value = null
            let type = null
            const keys = Object.keys(answer)
            if (keys.indexOf('mark') > - 1) {
                value = answer.mark
                type = 'mark'
            }
            else if (keys.indexOf('choice') > - 1) {
                value = `${answer.choice} - ${answer.choiceText}`
                type = 'choice'
            }
            else if (keys.indexOf('multiple') > - 1) {
                value = answer.multiple.map(choice => `${choice.choice} - ${choice.choiceText}`)
                type = 'multiple'
            }
            else {
                value = answer.comment
                type = 'comment'
            }

            return {
                profile,
                questionId: answer.question_id,
                text: answer.question_text,
                type,
                value
            }
        })
    }

    return payload
}

export default ({ dispatch, getState }) => next => action => {
    const state = getState()
    const pollDialogState = state[COMPONENT_KEY]
    let validityTest = null;
    const result = next(action);

    switch (action.type) {

        case UPDATER_INITIALIZED:
        case POLL_CONFIG_UPDATED:
            init();
            break;

        case HAS_NAVIGATED:
            const pollId = getParameterByName(QUERY_PARAMETER_KEY);
            // if query param was present with a pollId
			if (pollId) {
				showDialog(pollId)
			}
            break;

        case SET_POLL_STEP:
            setPollStep(action.poll_id, action.question_id, action.data)
            break;

        case SET_POLL_ID:
            validityTest = _validatePoll(action.poll_id, pollDialogState.poll_code)
            if (!validityTest.error) {
                setPage('formValid')
            }
            break;

        case SUBMIT_POLL:
            validityTest = _validatePoll(action.poll_id, pollDialogState.poll_code, action.type)
            if (!validityTest.error || validityTest.error === ERRORS.FILLED) {
                let data = getPollData(action.poll_id)
                data = _parseAnswers(data, pollDialogState.profile)
                submit(data, action.poll_id)
            }
            break;

        case SHOW_POLL_DIALOG:
            _validatePoll(action.poll_id, pollDialogState.poll_code)
            break;

        // case VALIDATE_POLL:
        //     _validatePoll(action.poll_id, action.poll_code, action.type)
        //     break;

        case VALIDATE_POLL_CODE:
            validityTest = _validatePoll(action.poll_id, action.poll_code, action.type)
            if (!validityTest.error) {
                setPage('formValid')
            }
            break;

        case GO_TO_PREVIOUS_POLL_STEP:
            setError(null)
            break;

        default:
    }
    return result;
};
