
import React, { PureComponent } from 'react';
import PropTypes    from 'prop-types';
import Measure      from 'react-measure';
import iscroll      from 'iscroll';
import { debounce } from 'lodash';

import { ISCROLL_OPTIONS, REFRESH_DELAY } from 'data/config/iScrollConfig';

import { isIOS, isWkWebview, isIphoneX } from 'src/core/util/browser';

import './UseRemainingHeight.scss';


const LOG_PREF = '[RemainingHeight] ';

class UseRemainingHeight extends PureComponent {

    constructor(props) {
        super(props);

        this.state = {
            contentStyle: {}
        };

        this.refreshIScroll = debounce(
            this.refreshIScroll.bind(this),
            50
        );
    }

    onContentDimensionUpdate = (measure) => {
        if (measure && measure.bounds) {
            this.setState({
                contentStyle: Object.assign({}, this.state.contentStyle, {
                    height: document.documentElement.clientHeight - measure.bounds.top - 1
                                + (isWkWebview() ? 20 : 0)
                                + (global.isCordovaContext && isIphoneX() ? 58 : 0),
                })
            });
        }
    }

    refreshIScroll () {
        if (this.iScrollInstance) {
            window.setTimeout(() => {
                this.iScrollInstance.refresh();
                console.log(LOG_PREF+'refreshed iscroll');
            }, REFRESH_DELAY);
        }
    }

    componentDidUpdate(){
        this.refreshIScroll();
    }
    componentDidMount(){
        this.refreshIScroll();
    }

    scrollTo = debounce(value => {
        if (this.iScrollInstance) {
            this.iScrollInstance.scrollTo(0, value*-1);
        }
        else if (this.containerEl) {
            this.containerEl.scrollTop = value;
        }
    }, 50);

    setContainerElement = el => {
        this.containerEl = el;

        // iOS: init iScroll
        if (isIOS() &&
                this.props.useIScroll !== false &&
                this.containerEl !== null) {

            if (this.iScrollInstance) {
                this.refreshIScroll();
            } else {
                this.iScrollInstance = new iscroll(this.containerEl, ISCROLL_OPTIONS);
            }
        }
    }

    render() {
        return (
            <Measure bounds onResize={this.onContentDimensionUpdate}>
                {({ measureRef }) => (
                    <div
                        className="remaining-height-container"
                        style={this.state.contentStyle}
                        ref={measureRef}>

                            { this.props.children }
                    </div>
                )}
            </Measure>
        );
    }

}

UseRemainingHeight.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.element,
        PropTypes.arrayOf(PropTypes.element),
    ]),
    useIScroll: PropTypes.bool,
};

export default UseRemainingHeight;
