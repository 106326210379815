
import { LANG_CHANGED } from 'src/store/actionTypes';

import { get as getLabels } from 'src/core/Lang';

import { langChanged } from 'src/store/reducers/commons';


const getDefaultState = () => ({
    labels: getLabels(),
});


export default (state = getDefaultState(), action) => {
    switch (action.type) {

        case LANG_CHANGED: return langChanged(state, action);

        default: return state;
    }
};
