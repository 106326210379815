
import { AUTO_DISMISS } from 'data/config/notifConfig';

import {
    SHOW_NOTIF,
    REMOVE_NOTIF,
} from 'src/store/actionTypes';

import { updateObject } from 'src/store/reducers/commons';
import { getRandomLetter } from 'src/core/util/JsTools';

import NotificationLevels  from './NotificationLevels';


const getDefaultState = () => ({
    notifications: [],
});


let iNotifId = 1;
/**
 * @see https://github.com/igorprado/react-notification-system#creating-a-notification
 * @param  {object} action
 * @return {object}
 */
function createNotificationObject(action) {
    let level = action.level || NotificationLevels.INFO,
        duration = typeof action.duration === 'number' ? action.duration : AUTO_DISMISS[level];

    return {
        uid        : getRandomLetter()+iNotifId++,
        // title      : action.title, // Bad display due to onsen '.notification' style applying...
        message    : action.message,
        level      : level,
        dismissible: duration === 0,
        autoDismiss: duration,
        _onRemove  : action.onRemove,
        // position values: tr (top right), tl (top left), tc (top center), br (bottom right), bl (bottom left), bc (bottom center)
        position   : action.position || 'tc',
    };
}


/**
 * @see https://github.com/igorprado/react-notification-system#creating-a-notification
 * @param  {object} state
 * @param  {object} action
 * @return {object}
 */
function _showNotif(state, action) {
    if (!action.message) {
        console.warn('Skipping SHOW_NOTIF action because of empty \'message\' property');
        return state;
    }
    return updateObject(state, {
        notifications: state.notifications.concat([ createNotificationObject(action) ]),
    });
};


/**
 * @param  {object} state
 * @param  {object} action
 * @return {object}
 */
function _removeNotif(state, action) {
    return updateObject(state, {
        notifications: state.notifications.filter(notif => notif.uid !== action.notification.uid),
    });
};


export default (state = getDefaultState(), action) => {
    switch (action.type) {

        case SHOW_NOTIF  : return _showNotif(state, action);
        case REMOVE_NOTIF: return _removeNotif(state, action);

        default: return state;
    }
};
