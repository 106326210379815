
import * as config from 'data/config/config';

import {
    SHOW_LOGIN_DIALOG,
    HIDE_LOGIN_DIALOG,
    SET_LOGIN_IDENTIFICATION,
    SET_LOGIN_ERROR,
    UPDATE_PROFILE,
    LANG_CHANGED,
    PERFORM_LOGIN,
} from 'src/store/actionTypes';

import {
    get as getLabels,
} from 'src/core/Lang';

import {
    langChanged,
    updateObject,
} from 'src/store/reducers/commons';

import { getAccount } from 'src/core/login/LoginPersistence';


const getDefaultState = () => ({
    labels: getLabels(),
    isOpen: false,
    identification: null,
    isLoging: false,
    error: null,
    forgottenPasswordURL: config.LOGIN.forgottenPasswordURL,
    createAccountURL: config.LOGIN.createAccountURL,
    tosURL: config.LOGIN.tosURL,
});


const _toggleDialog = (state, action, value) => {
    let newValues = { isOpen: value };

    if (value === true) {
        let account = getAccount();
        newValues.login    = account ? account.username : '';
        newValues.password = account ? account.password : '';
        newValues.isLoging = false;
        newValues.error = null;
    } // nb: it is useless to update account values on modal hide

    return updateObject(state, newValues);
};

const _setLoginIdentification = (state, action, value) => (
    updateObject(state, { isLoging: false, identification: value })
)

const _setLoginError = (state, action, value) => (
    updateObject(state, {
        error: value,
        isLoging: false,
    })
);

const _updateProfile = (state, action) => (
    updateObject(state, { profile: action.profile })
);

const _startLogin = (state, action) => (
    updateObject(state, { isLoging: true, error : null, })
);


export default (state = getDefaultState(), action) => {
    switch (action.type) {

        case LANG_CHANGED: return langChanged(state, action);
        case SHOW_LOGIN_DIALOG: return _toggleDialog(state, action, true);
        case HIDE_LOGIN_DIALOG: return _toggleDialog(state, action, false);
        case SET_LOGIN_IDENTIFICATION: return _setLoginIdentification(state, action, action.identification);
        case SET_LOGIN_ERROR: return _setLoginError(state, action, action.error);
        case UPDATE_PROFILE: return _updateProfile(state, action);
        case PERFORM_LOGIN: return _startLogin(state, action);
        default: return state;
    }
};
