import React from 'react';

import { ProgressCircular } from 'react-onsenui';


const ListLoaderElement = () => (

    <li key="0" className="list-loader">
        <ProgressCircular indeterminate></ProgressCircular>
    </li>
)
export default ListLoaderElement;