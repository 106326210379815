
// app is considered 'booted' when needed resources have been fetched, parsed, are ready to be used, e.g:
//  - data file
//  - ad configuration
let booted = false;

export const hasBooted = () => booted === true;

export function setBooted(value) {
    booted = value;
}
