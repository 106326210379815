
let queuedActions = [];

export const queue = (cb) => { queuedActions.push(cb); };

/**
 * [description]
 * @return {[type]} [description]
 */
export const performQueuedActions = () => {
    //console.log(LOG_PREF + `Executing ${queuedActions.length} queued functions`);
    while (queuedActions.length) {
        queuedActions.shift()();
    }
};
