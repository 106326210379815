import { INBOX_PAGE_KEY } from 'src/pages/pagesKeys.js';

import InboxPage    from 'src/pages/inbox/InboxPage';
import inboxReducer from 'src/pages/inbox/inboxReducer';

export default {
    key      : INBOX_PAGE_KEY,
    path     : '/inbox',
    component: InboxPage,
    elId     : 'inbox',
    getReduxReducer: (state, action) => inboxReducer(state[INBOX_PAGE_KEY], action)
};
