
import { DISABLE_FAVORITE_ICON } from 'data/config/listConfig';

import {
    CONFIG_JSON_LOADED,
    POLL_CONFIG_LOADED,
    ALL_FAVORITES_DELETED,
    CLEAR_SEARCH_RESULTS,
    DATA_UPDATED,
    LANG_CHANGED,
    PROFILE_CHANGED,
    SEARCH_PERFORMED,
    TOGGLE_FAVORITE,
    TOGGLE_LOCATION_STATUS,
    TOGGLE_MENU,
    UPDATE_PAGE_STATE,
} from 'src/store/actionTypes';

import {
    configJsonLoaded,
    pollConfigLoaded,
    getPageCommonDefaultState,
    langChanged,
    profileChanged,
    setSearchPlaceHolder,
    toggleLocationStatus,
    toggleMenu,
    updateObject,
    updatePageState,
} from 'src/store/reducers/commons';

import STATUS from 'src/store/fetchStatuses';

import { SEARCH_PAGE_KEY } from 'src/pages/pagesKeys';


const getDefaultState = () => Object.assign({}, getPageCommonDefaultState(SEARCH_PAGE_KEY), {
    favIconDisabled: DISABLE_FAVORITE_ICON === true,
});


function _searchPerformed(state, action){
    if (action.pageKey === SEARCH_PAGE_KEY) {
        return updateObject(state, {
                   isPending : action.status === STATUS.PENDING,
                   searched  : action.searched,
                   results   : action.results,
                   favorites : action.favorites,
                   totalCount: action.totalCount,
                   relaunch  : false,
               });
    }
    return state;
}

const _clearSearchResults = (state, action) => (
    updateObject(state, {
        isPending : action.status === STATUS.PENDING,
        results   : null,
        searched  : '',
        totalCount: 0,
    })
);

const _toggleFavorite = (state, action) => (
    updateObject(state, { favorites: action.favorites })
);

const _allFavoritesDeleted = state => updateObject(state, { favorites: {} });


export default (state = getDefaultState(), action) => {
    switch (action.type) {

        case CONFIG_JSON_LOADED      : return configJsonLoaded(state);
        case ALL_FAVORITES_DELETED   : return _allFavoritesDeleted(state);
        case CLEAR_SEARCH_RESULTS    : return _clearSearchResults(state, action);
        case DATA_UPDATED            : return setSearchPlaceHolder(state, action);
        case LANG_CHANGED            : return langChanged(state, action);
        case POLL_CONFIG_LOADED      : return pollConfigLoaded(state, action);
        case PROFILE_CHANGED         : return profileChanged(state, action, SEARCH_PAGE_KEY);
        case SEARCH_PERFORMED        : return _searchPerformed(state, action);
        case TOGGLE_FAVORITE         : return _toggleFavorite(state, action);
        case TOGGLE_LOCATION_STATUS  : return toggleLocationStatus(state, action);
        case TOGGLE_MENU             : return toggleMenu(state, action, SEARCH_PAGE_KEY);
        case UPDATE_PAGE_STATE       : return updatePageState(state, action, SEARCH_PAGE_KEY);

        default: return state;
    }
};
