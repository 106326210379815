
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Page } from 'react-onsenui';
import ReactCSSTransitionGroup from 'react-addons-css-transition-group';

import config from 'data/config/config';

import {
    DATA_TYPE_EXHIBITORS,
    CATEGORIES_DATA_TYPE,
    CATEGORIES_MAPPING
} from 'data/config/dataConfig';

import { SEARCH_MIN_CHARS } from 'data/config/searchConfig';
import { FAVORITES_PAGE_KEY, LIST_PAGE_KEY, SEARCH_PAGE_KEY, LIST_GROUPS_PAGE_KEY } from 'src/pages/pagesKeys';

import SearchField from 'src/components/search/SearchField';
import { isWkWebview, isIphoneX, isIpad } from 'src/core/util/browser';

import {
    STATUS as SYNCHRO_FAV_STATUS,
} from 'src/core/favorites/SynchronizedFavoritesService';

// import SearchPlaceTypes from 'src/components-standalone/search-place-dialog/SearchPlaceTypes';

import './AppToolbar.scss';


// Warning: this value is defined in ./AppToolbar.scss, but also here due to computation issues
// (sometimes offsetHeight: 0 despite a call to requestAnimationFrame after component render/update)
const TOOLBAR_HEIGHT_FALLBACK = isIphoneX() ? 92 : 48;

const getButton = (iconClassName, iconStyle, action, label) => (
    <span className="app-toolbar-icon" onClick={action} title={label}>
        <span className={iconClassName} style={iconStyle} />
    </span>
);

class AppToolbar extends PureComponent {

    state = {
        childrenStyle: null,
    }

    navigateToHome = () => {
        let homePage = config.getHomePage(this.props.profile);
        this.props.actions.navigate(homePage.pageKey, homePage.props);
    }

    navigateToFavoritesPage = () => {
        this.props.actions.navigate(FAVORITES_PAGE_KEY);
    }

    handleSearchButtonClick = () => {
        if (this.props.pageKey === LIST_PAGE_KEY) {
            this.showSearchField();
        } else {
            this.props.actions.navigate(SEARCH_PAGE_KEY);
        }
    }

    showSearchField = () => {
        this.setState({ searchFieldVisible: true });
    }
    hideSearchField = () => {
        this.setState({ searchFieldVisible: false });
    }
    clearSearchResults = () => {
        this.hideSearchField();
        if (typeof this.props.onSearchClear === 'function') {
            this.props.onSearchClear();
        }
    }

    performSearch = str => {
        this.props.actions.performSearch(str, this.props.pageKey, this.props.contextualDataTypes);
    }

    openMenu = () => {
        // this.hideSearchField();
        this.props.actions.openMenu(this.props.pageKey);
    }

    setWrapperEl = el => {
        this.wrapperEl = el;
    }

    setToolbarEl = el => {
        this.toolbarEl = el;
    }

    updateChildrenStyle = () => {
        let style;

        if (this.wrapperEl && this.toolbarEl) {
            const
                pageTop = this.wrapperEl.parentNode.offsetTop,
                contentTop = this.toolbarEl.offsetHeight || TOOLBAR_HEIGHT_FALLBACK;

            let height = document.documentElement.clientHeight - contentTop - pageTop + (isWkWebview() ? 20 : 0) + (global.isCordovaContext && isIphoneX() ? 58 : 0)

            if (global.isCordovaContext && isIpad()) {
                const clientHeight = document.documentElement.clientHeight
                const innerHeight = window.innerHeight
                const offset = contentTop + pageTop
                height = innerHeight > clientHeight ? innerHeight - offset : clientHeight - offset
            }

            style = {
                top: contentTop,
                height
            };
        } else {
            style = null;
        }

        if (JSON.stringify(style) !== JSON.stringify(this.state.childrenStyle)) {
            this.setState({ childrenStyle: style, });
        }
    }

    componentDidMount() {
        window.requestAnimationFrame(this.updateChildrenStyle);
    }
    componentDidUpdate() {
        window.requestAnimationFrame(this.updateChildrenStyle);
    }

    getSearchFieldPlaceholder() {
        const placeholder = []
        this.props.contextualDataTypes.forEach(dataType => {
            let type = dataType

            if (CATEGORIES_DATA_TYPE.indexOf(dataType) > -1) {
                type = CATEGORIES_MAPPING[dataType]
            }

            const text = this.props.labels.data[type].plural.toLowerCase()

            if (placeholder.indexOf(text) === -1) {
                placeholder.push(text)
            }
        })

        if (this.props.labels.search.keywords) {
            placeholder.push(this.props.labels.search.keywords)
        }

        return placeholder.join(', ')
    }


    getToolbar() {

        let rootCategoryButtonInToolbar = !this.state.searchFieldVisible && this.props.rootCategoryButtonInToolbar,
            hasHomeButton = !this.state.searchFieldVisible && this.props.hasHomeButton,
            hasFavButton = !this.state.searchFieldVisible && this.props.hasFavButton === true,
            hasSearchButton = !this.state.searchFieldVisible && this.props.hasSearchButton === true,
            synchroFavBtnStatus = this.props.isSynchroFavFeatureEnabled
                && !this.state.searchFieldVisible
                && this.props.synchroFavBtnStatus;

        return (
            <div className="app-toolbar" ref={this.setToolbarEl}>

                <div className="left">
                    { this.props.hasBackButton &&
                         getButton('zmdi zmdi-arrow-back toolbar-back-btn', null, this.props.actions.navigateBack, this.props.labels.common.goBack)
                    }
                </div>

                { this.state.searchFieldVisible &&
                    <ReactCSSTransitionGroup
                        component="div"
                        className="center"
                        transitionName="search-field"
                        transitionAppear={true}
                        transitionAppearTimeout={500}
                        transitionEnterTimeout={500}
                        transitionLeaveTimeout={500}>

                        <SearchField
                            key={0}
                            placeholder={this.getSearchFieldPlaceholder()}
                            perform={this.performSearch}
                            clearResults={this.clearSearchResults}
                            minChars={this.props.pageKey === LIST_PAGE_KEY ? 1 : SEARCH_MIN_CHARS} />
                    </ReactCSSTransitionGroup>
                }



                { !this.state.searchFieldVisible &&
                    <div className="center content-font">{this.props.contextualTitle || this.props.title}</div>
                }

                <div className="right">
                    { rootCategoryButtonInToolbar &&
                        getButton('fa fa-list', null, this.props.navigateToRootCategory, this.props.labels.common.goToRootCategory)
                    }
                    { hasHomeButton &&
                        getButton('fa fa-home', null, this.navigateToHome, this.props.labels.common.goToHome)
                    }
                    { hasFavButton === true &&
                        getButton('zmdi zmdi-star', null, this.navigateToFavoritesPage, this.props.labels.common.goToFavorites)
                    }
                    { hasSearchButton === true &&
                        getButton('zmdi zmdi-search', null, this.handleSearchButtonClick, this.props.labels.common.goToSearch)
                    }
                    { synchroFavBtnStatus &&
                        getButton(
                            `fa fa-refresh synchro-fav-btn synchro-fav-btn-${this.props.synchroFavBtnStatus}`,
                            null,
                            this.props.actions.synchroFavoritesIconClicked,
                            ''
                        )
                    }
                    { this.props.hasMenuButton === true &&
                        getButton('zmdi zmdi-menu', null, this.openMenu, this.props.labels.common.openMenu)
                    }
                </div>
            </div>
        );
    }


    setHeightOnChild = child => {
        if (this.state.childrenStyle && this.state.childrenStyle.height) {
            return React.cloneElement(child, { height: this.state.childrenStyle.height });
        }
        return child;
    }

    render() {
        // With toolbar
        if (this.props.isDisplayed !== false) {
            return (
                <Page>
                    <div className="app-toolbar-wrapper" ref={this.setWrapperEl}>

                        { this.getToolbar() }

                        <div className="app-toolbar-children" style={this.state.childrenStyle}>
                            { React.Children.map(this.props.children, this.setHeightOnChild) }
                        </div>
                    </div>
                </Page>
            );
        }
        // without toolbar
        else {
            return (
                <Page>
                    <div className="app-toolbar-wrapper">
                        <div className="app-toolbar-children">
                            {this.props.children}
                        </div>
                    </div>
                </Page>
            );
        }
    }

}

AppToolbar.propTypes = {
    labels         : PropTypes.object.isRequired,
    actions        : PropTypes.object.isRequired,
    profile        : PropTypes.string.isRequired,
    isDisplayed    : PropTypes.bool,
    title          : PropTypes.string.isRequired,
    contextualTitle: PropTypes.string,
    pageKey        : PropTypes.string,
    hasBackButton  : PropTypes.bool,
    hasHomeButton  : PropTypes.bool,
    hasFavButton   : PropTypes.bool,
    hasSearchButton: PropTypes.bool,
    onSearchClear  : PropTypes.func,
    synchroFavBtnStatus: PropTypes.string,
    rootCategoryButtonInToolbar : PropTypes.bool,
    navigateToRootCategory: PropTypes.func,
};

export default AppToolbar;
