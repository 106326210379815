
import config from 'data/config/config';
import { DISABLE_FAVORITE_ICON } from 'data/config/listConfig';

import {
    CONFIG_JSON_LOADED,
    POLL_CONFIG_LOADED,
    ALL_FAVORITES_DELETED,
    DATA_UPDATED,
    GROUPED_ITEMS_FETCHED,
    LANG_CHANGED,
    PROFILE_CHANGED,
    TOGGLE_FAVORITE,
    TOGGLE_LOCATION_STATUS,
    TOGGLE_MENU,
    UPDATE_PAGE_STATE,
} from 'src/store/actionTypes';

import STATUS from 'src/store/fetchStatuses';

import {
    configJsonLoaded,
    pollConfigLoaded,
    dataUpdated,
    getPageCommonDefaultState,
    langChanged,
    profileChanged,
    setIsFavoriteFalse,
    toggleLocationStatus,
    toggleMenu,
    updateObject,
    updatePageState,
} from 'src/store/reducers/commons';

import { LIST_GROUPS_PAGE_KEY } from 'src/pages/pagesKeys';


const getDefaultState = () => Object.assign({}, getPageCommonDefaultState(LIST_GROUPS_PAGE_KEY), {
    isPending   : true,
    favorites   : [],
    sideIndexThreshold: config.LIST_GROUPS_SHOW_SIDEINDEX_IF_ABOVE,
    favIconDisabled: DISABLE_FAVORITE_ICON === true,
});


const _groupedItemsFetched = (state, action) => {
    // Initial action
    return updateObject(state, {
        groupedItems: action.groupedItems,
        isPending   : action.status === STATUS.PENDING,
        itemNotFound: action.status === STATUS.NO_RESULT,
        favorites   : action.favorites,
        contextualTitle: action.contextualTitle,
        ad          : action.ad,
        shouldFetch : false,
    });
};

const toggleFavorite = (state, action) => {
    if (action.favListUpdated && state.input && action.dataType === state.input.dataType) {
        return updateObject(state, { favorites: action.favorites[state.input.dataType] });
    }
    return state;
};


export default (state = getDefaultState(), action) => {
    switch (action.type) {

        case CONFIG_JSON_LOADED: return configJsonLoaded(state);
        case POLL_CONFIG_LOADED : return pollConfigLoaded(state, action);
        case ALL_FAVORITES_DELETED: return setIsFavoriteFalse(state);
        case DATA_UPDATED: return dataUpdated(state, action, state.input ? state.input.dataType : []);
        case GROUPED_ITEMS_FETCHED: return _groupedItemsFetched(state, action);
        case LANG_CHANGED: return langChanged(state, action);
        case PROFILE_CHANGED: return profileChanged(state, action, LIST_GROUPS_PAGE_KEY);
        case TOGGLE_FAVORITE: return toggleFavorite(state, action);
        case TOGGLE_LOCATION_STATUS: return toggleLocationStatus(state, action);
        case TOGGLE_MENU: return toggleMenu(state, action, LIST_GROUPS_PAGE_KEY);
        case UPDATE_PAGE_STATE: return updatePageState(state, action, LIST_GROUPS_PAGE_KEY);

        default: return state;
    }
};
