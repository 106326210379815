/**
 * GR 2019/04/30
 *
 * (one more) Problem on iOS:
 * scroll fast, while the momentum is ongoing if you update el.scrollTop value it has no effect.
 *
 * This helper stops momentum, update scroll position, make the element scrollable again.
 *
 * @param  {DOM element} el
 */
function iosScroll(el) {
    if (!el) {
        console.error('[iosScroll] missing DOM element argument');
        return null;
    }

    var timeout = null;

    function setWebkitOverflowScrolling(value) {
        el.style['-webkit-overflow-scrolling'] = value;
    }

    /**
     * Make the element scrollable again
     */
    function resetWebkitOverflowScrolling() {
        if (timeout) {
            window.clearTimeout(timeout);
        }
        timeout = window.setTimeout(_resetWebkitOverflowScrolling, 80);
    }
    function _resetWebkitOverflowScrolling() {
        setWebkitOverflowScrolling('touch');
        timeout = null;
        // console.log('[iosScroll] reset to touch');
    }

    return function scrollTo(offset) {
        // Stop momentum
        setWebkitOverflowScrolling('auto');

        el.scrollTop = offset;

        // Make the element scrollable again
        resetWebkitOverflowScrolling();
    }
}

export default iosScroll;