import { EVENT_PAGE_KEY } from 'src/pages/pagesKeys.js';

import EventPage        from 'src/pages/event/EventPage';
import eventPageReducer from 'src/pages/event/eventPageReducer';

export default {
    key      : EVENT_PAGE_KEY,
    path     : '/event',
    component: EventPage,
    elId     : 'event-page',
    getReduxReducer: (state, action) => eventPageReducer(state[EVENT_PAGE_KEY], action),
};
