
import React from 'react';
import PropTypes from 'prop-types';
import { GestureDetector } from 'onsenui';
import Measure from 'react-measure';

import { LIST_PAGE_KEY, FLIGHTS_SCHEDULE_PAGE_KEY } from 'src/pages/pagesKeys';

import {
    NON_CATEGORIES_DATA_TYPE,
    CATEGORIES_MAPPING,
    DATA_TYPE_FLIGHTS_SCHEDULE,
} from 'data/config/dataConfig';

import { getAlphabeticalListByIndexThreshold } from 'data/config/listConfig';

import STATUS from 'src/store/fetchStatuses';


import AdBanner         from 'src/components/ad-banner/AdBanner';
import AllOnMapButton   from 'src/components/all-on-map-button/AllOnMapButton';
import AlphabeticalList from 'src/components/list/AlphabeticalList';
import CTAButton        from 'src/components/cta-button/CTAButton';
import DetailFixedTitle from 'src/components/detail-fixed-title/DetailFixedTitle';
import IphonexFlexMargin from 'src/components/iphonex-flex-margin/IphonexFlexMargin';
import List             from 'src/components/list/List';
import Loader           from 'src/components/loader/Loader';
import TypeBar          from 'src/components/type-bar/TypeBar';
import Url              from 'src/components/url/Url';
import { stripToIds }   from 'src/pages/map/mapUtil';



class ListPageContent extends React.Component {

    state = {}
    pageKey = LIST_PAGE_KEY

    getDetail() {
        let dataTypes = Object.keys(this.props.items);

        return dataTypes.map(dataType => {

            let items = this.props.items[dataType];
            if (items.length === 0) return null

            let listProps = {
                key              : dataType,
                actions          : this.props.actions,
                labels           : this.props.labels,
                favorites        : this.props.favorites[dataType],
                isPending        : this.props.status[dataType] === STATUS.PENDING,
                dataType         : dataType,
                displayFavorites : this.props.favIconDisabled !== true,
                height           : this.state.listHeight,
                pageKey          : this.pageKey,
            };

            let list;
            if (items.data.all) {
                listProps.items = items.data.all;
                list = <List {...listProps} />;
            } else {
                listProps.items = items.data;
                listProps.indexes = Object.keys(items.data);
                listProps.getSeparators = items.getSeparators;
                listProps.contentByIndex = items.length > getAlphabeticalListByIndexThreshold();

                list = <AlphabeticalList associatedPageKey={this.pageKey} {...listProps} />;
            }

            // If several lists are shown, wrap with a type separator
            if (dataTypes.length > 1) {
                return (
                    <TypeBar
                        key={dataType}
                        label={this.props.labels.data[dataType].plural}>
                        {list}
                    </TypeBar>
                );
            } else {
                return list;
            }
        });
    }

    containerElementDidRender = el => {
        // @see https://onsen.io/v2/docs/guide/js/#adding-page-content  § Gesture detector
        if (el) {
            GestureDetector(el).dispose();
        }
    }

    onDimensionsUpdate = measure => {
        this.setState({ listHeight: measure.client.height });
    }

    isListEmpty = () => {        
        const hasItems = this.props.items !== null && typeof this.props.items === 'object';
        const noData = hasItems && (Object.keys(this.props.items).length === 0
            || Object.keys(this.props.items).every(key => !this.props.items[key]
                || (Array.isArray(this.props.items[key]) && this.props.items[key].length === 0)
                || (this.props.items[key].data
                    && typeof this.props.items[key].data === 'object'
                    && Object.keys(this.props.items[key].data).length === 0)
                || (this.props.items[key].data
                    && typeof this.props.items[key].data === 'object'
                    && Object.keys(this.props.items[key].data).every(subKey => !this.props.items[key].data[subKey]
                        || (Array.isArray(this.props.items[key].data[subKey])
                            && this.props.items[key].data[subKey].length === 0)))))
                            
        return {
            hasItems,
            noData
        }
    }

    renderDisplayPdfButton = () => {
        if (this.props.hasDisplayPdfButton) {
            return (
                 <div className="cta-btn-container">
                    <Url
                        callback={this.props.actions.linkClicked}
                        href={this.props.agendaAsPdfUrl}
                        noCache // disable cache
                        target="_blank" className="cta-btn"
                        alt={this.props.labels.common.openAgendaAsPdf}>

                            {this.props.labels.common.openAgendaAsPdf}
                    </Url>
                </div>
            )
        }

        return null
    }

    render() {
        const { hasItems, noData } = this.isListEmpty()

        return (
            <div className={this.props.className}
                 style={{ height: this.props.height }}
                 ref={this.containerElementDidRender}>

                { this.props.isOnGeneralAircraftList &&
                    <FlightsScheduleHeader actions={this.props.actions} labels={this.props.labels} />
                }

                { this.props.header &&
                    <DetailFixedTitle
                        title={this.props.header}
                        hideFav={true}
                        labels={this.props.labels} />
                }

                { this.props.isOnProgrammeRootPage &&
                    <HeaderProgramme labels={this.props.labels} />
                }

                { this.props.hasShowOnMapButton &&
                    <AllOnMapButton
                        labels={this.props.labels}
                        actions={this.props.actions}
                        data={ stripToIds(this.props.items) } />
                }

                {this.renderDisplayPdfButton()}

                <Measure client onResize={this.onDimensionsUpdate}>
                    {({ measureRef }) => (
                        <div ref={measureRef} className="list-page-content">
                            {!hasItems && <Loader labels={this.props.labels} />}
                            {noData && <div className="list-empty">{this.props.labels.common.noData}</div>} 
                            {!noData && hasItems && this.getDetail()}
                        </div>
                    )}
                </Measure>

                <AdBanner
                    actions={this.props.actions}
                    associatedPageKey={this.pageKey}
                    listInputs={this.props.inputs}
                    adConfig={this.props.adConfig}
                    labels={this.props.labels} />

                <IphonexFlexMargin />
            </div>
        );
    }


}


export function HeaderProgramme ({ labels }) {
    return (
        <section
            style={{
                padding: "1em 0.7em",
                fontSize: '0.7em',
                color: '#007e4f',
                borderBottom: '1px solid lightgrey'
            }}>
            {labels.contributions.programmeSlogan}
        </section>
    );
}

export function FlightsScheduleHeader({ actions, labels }) {
    return (
        <CTAButton
            action={() => actions.navigate(FLIGHTS_SCHEDULE_PAGE_KEY)}
            label={labels.data[DATA_TYPE_FLIGHTS_SCHEDULE].title} />
    );
}

ListPageContent.props = {
    className: PropTypes.string,
    isOnGeneralAircraftList: PropTypes.bool,
    isOnProgrammeRootPage: PropTypes.bool,
    hasShowOnMapButton: PropTypes.bool,
    hasDisplayPdfButton: PropTypes.bool,
    agendaAsPdfUrl: PropTypes.string,
    inputs: PropTypes.object.isRequired,
    items: PropTypes.object,
    adConfig: PropTypes.object,
    header: PropTypes.string,
    favorites: PropTypes.object,
    status: PropTypes.object,
    favIconDisabled: PropTypes.bool,
    actions: PropTypes.object.isRequired,
    labels: PropTypes.object.isRequired,
    height: PropTypes.number, // prop set from AppToolbar
};

export default ListPageContent;
