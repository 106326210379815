
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import config from 'data/config/config';

import share from 'src/core/share';

import './ShareButtons.scss';


class ShareButton extends PureComponent {

    /**
     * When main button is clicked, display modal with a list of social networks
     * @param {object} e
     */
    onShareButtonClick = e => {
        share(
            this.props.name,
            this.props.queryString,
            this.props.description,
            this.props.image,
            this.props.actions,
            this.props.labels
        );
    }

    render() {
        if (config.SHARE.FEATURE_ENABLED !== true) {
            return null;
        }

        if (Array.isArray(config.SHARE.RESTRICTIONS)) {
            let restriction = config.SHARE.RESTRICTIONS.find(restr => restr.pageKey === this.props.pageKey);
            if (restriction) {
                // A restriction has been defined for this page
                if (restriction.for.indexOf(this.props.profile) !== -1) {
                    // Restriction applies for this profile, so skip rendering share button
                    return null;
                }
            }
        }

        return (
            <div>
                <div className="share-btn fa fa-share-alt"
                     title={this.props.labels.common.share}
                     onClick={this.onShareButtonClick} />
            </div>
        );
    }
}

ShareButton.propTypes = {
    name       : PropTypes.string.isRequired,
    queryString: PropTypes.string.isRequired,
    description: PropTypes.string,
    image      : PropTypes.string,
    // common props
    labels     : PropTypes.object.isRequired,
    actions    : PropTypes.object.isRequired,
    profile    : PropTypes.string.isRequired,
    pageKey    : PropTypes.string.isRequired,
};

export default ShareButton;