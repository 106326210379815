
import config from 'data/config/config';

import { SHOW_MOBILESPOT_BUTTON } from 'data/config/mapConfig';

import {
    CONFIG_JSON_LOADED,
    POLL_CONFIG_LOADED,
    DATA_UPDATED,
    LANG_CHANGED,
    MAP_LOADED,
    MAP_RELOAD,
    PROFILE_CHANGED,
    TOGGLE_LOCATION_STATUS,
    TOGGLE_MENU,
    UPDATE_PAGE_STATE,
    WINDOW_RESIZED,
} from 'src/store/actionTypes';

import {
    configJsonLoaded,
    pollConfigLoaded,
    getPageCommonDefaultState,
    langChanged,
    profileChanged,
    toggleLocationStatus,
    toggleMenu,
    updateObject,
    updatePageState,
    windowResized,
} from 'src/store/reducers/commons';

import { MAP_PAGE_KEY } from 'src/pages/pagesKeys';


const getDefaultState = () => Object.assign({}, getPageCommonDefaultState(MAP_PAGE_KEY), {
    hasMobileSpotButton  : SHOW_MOBILESPOT_BUTTON === true,
    mobigeoApiKey        : config.MOBIGEO_API_KEY,
    searchButtonInToolbar: false, // override
});


/**
 * Map initialization:
 *   - App is ready for map loading when data is loaded
 *
 * Map restart if:
 *   - data has been updated OR at least an asset whose path contains 'files/maps/' has been updated
 *   - map is not currently displayed
 *
 * @param {boolean} value
 */
const _mapShouldRestart = (state, action, value) => {
    return updateObject(state, { shouldRestart: value });
};

const _dataUpdated = (state, action) => {
    // Detect when app has initialized (meaning data and assets are ready for MobiGeo)
    if (state.isDataReady !== true) {
        return updateObject(state, { isDataReady: true });
    }
    return state;
};


export default (state = getDefaultState(), action) => {
    switch (action.type) {

        case CONFIG_JSON_LOADED: return configJsonLoaded(state);
        case POLL_CONFIG_LOADED: return pollConfigLoaded(state, action);
        case DATA_UPDATED     : return _dataUpdated(state, action);
        case LANG_CHANGED     : return langChanged(state, action);
        case MAP_LOADED       : return _mapShouldRestart(state, action, false);
        case MAP_RELOAD       : return _mapShouldRestart(state, action, true);
        case PROFILE_CHANGED  : return profileChanged(state, action, MAP_PAGE_KEY);
        case TOGGLE_LOCATION_STATUS: return toggleLocationStatus(state, action);
        case TOGGLE_MENU      : return toggleMenu(state, action, MAP_PAGE_KEY);
        case UPDATE_PAGE_STATE: return updatePageState(state, action, MAP_PAGE_KEY);
        case WINDOW_RESIZED   : return windowResized(state, action);

        default: return state;
    }
};
