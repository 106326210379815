
import * as localforage from 'localforage';

import { INBOX_PAGE_KEY } from 'src/pages/pagesKeys';

import { playAction } from 'src/store/reducers/utils';

import {
    ADD_BULK_MESSAGES,
    ADD_DUPLICATE_MESSAGE,
    ADD_MESSAGE,
    DB_ENDPOINT,
    EMPTY_INBOX,
    MESSAGE_ACTIONED,
} from './inboxReducer';



export default ({ dispatch, getState }) => next => action => {

    const previousState = getState();
    // always process the action first
    const returnValue = next(action);


    // each action supposed to change the messages must be saved inside the DB
    switch ( action.type ) {

        case ADD_MESSAGE:
        case ADD_DUPLICATE_MESSAGE:
        case ADD_BULK_MESSAGES:
        case EMPTY_INBOX:

            const { byId, byDate, isReady } = getState()[INBOX_PAGE_KEY];
            if ( isReady === true ) {

                // saves the message in localforage
                localforage.setItem(
                    DB_ENDPOINT,
                    {
                        byId,
                        byDate
                    }
                )
                    .then(() => {} )
                    .catch(err => {});
            }
            break;


        case MESSAGE_ACTIONED:{

            // this means the user clicked the alert with the intent of following the action
            const messageId = previousState[INBOX_PAGE_KEY].notif;
            const message = getState()[INBOX_PAGE_KEY].byDate.filter(message => message.id === messageId)[0];

            if (message && message.action) {
                console.debug(message.action);
                setTimeout(playAction(message.action, dispatch));
            }
            break;
        }


        default: // for linter
    }

    return returnValue;
};
