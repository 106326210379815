
import React from 'react';
import PropTypes from 'prop-types';

import Url from 'src/components/url/Url';

import { isAndroid } from 'src/core/util/browser';

const DetailSocial = ({ data, actions }) => {
    const target = global.isCordovaContext && !isAndroid() ? '_system' : null
    if (data && (data.tw || data.fb || data.ig || data.ln || data.yt)) {
        return (
            <div className="free-row">
                <div className="prop-img">
                    <div className="prop-left">
                        <div className="fiche-contact-icon">
                            <span className="fa fa-comments-o" style={{ fontSize: '20px' }}/>
                        </div>
                    </div>
                    <div className="prop-right social-icons-container">
                        { data.tw && <Url className="fa fa-twitter-square"  callback={actions.linkClicked} href={data.tw} target={target} /> }
                        { data.fb && <Url className="fa fa-facebook-square" callback={actions.linkClicked} href={data.fb} target={target} /> }
                        { data.ig && <Url className="fa fa-instagram" callback={actions.linkClicked} href={data.ig} target={target} /> }
                        { data.ln && <Url className="fa fa-linkedin-square" callback={actions.linkClicked} href={data.ln} target={target} /> }
                        { data.yt && <Url className="fa fa-youtube-square" callback={actions.linkClicked} href={data.yt} target={target} /> }
                    </div>
                </div>
            </div>
        )
    }

    return null
}

DetailSocial.propTypes = {
    data: PropTypes.object,
    actions: PropTypes.object.isRequired,
};
export default DetailSocial;
