import React from 'react'

export default (props, state ) => {
    const { max, yRefreshing, y, phase } = state
    const bgColor = '#FFF'
    const p = Math.atan(y / max)
    const refreshed = phase === 'refreshed'

    const distance = Math.max(refreshed ? Math.atan(1) : p, 0) * max - 10

    if (y <= 0.00000001) return null

    return (
        <div
            className='refresh-spinner-container'
            style={{
                top: distance
            }}
        >
            <div
                key='pull'
                className='refresh-spinner-wrapper'
                style={{
                    transform: `translate(0, -100%) scale(${refreshed ? p : 1},${refreshed ? p : 1})`,
                    backgroundColor: bgColor
                }}
            >
                <div
                    style={{
                        transform:`rotate(${yRefreshing}deg)`
                    }}
                >
                    <div className={`infinit-table-spinner ${phase === 'refreshing' ? 'rotate' : ''}`}/>
                </div>
            </div>
        </div>
    )
}
