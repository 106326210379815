
import config from 'data/config/config';

const LOCALSTORAGE_LOGIN_KEY = 'loginToken';
const LOCALSTORAGE_ACCOUNT_KEY = 'account';


/**
 * TOKEN getter
 * @return {string}
 */
export function getToken() {
    let data = JSON.parse(window.localStorage.getItem(LOCALSTORAGE_LOGIN_KEY));

    return data ? data.token : null;
}

/**
 * TOKEN setter
 * @param {string} value
 */
export function setToken(value) {
    let expiry = Infinity;
    if (config.LOGIN.expiryHours != null) {
        expiry = new Date().getTime() + 1000 * 60 * 60 * config.LOGIN.expiryHours;
    }

    let data = {
        token : value,
        expiry: expiry
    };

    window.localStorage.setItem(LOCALSTORAGE_LOGIN_KEY, JSON.stringify(data));
}


/**
 * ACCOUNT getter
 * @return {object}
 */
export function getAccount() {
    let data = JSON.parse(window.localStorage.getItem(LOCALSTORAGE_ACCOUNT_KEY));

    return data || null;
}

/**
 * ACCOUNT setter
 * @param {object}
 */
export function setAccount(value) {
    window.localStorage.setItem(LOCALSTORAGE_ACCOUNT_KEY, JSON.stringify(value));
}


/**
 * TOKEN EXPIRATION getter
 * @return {number}
 */
export function getTokenExpiry() {
    let data = JSON.parse(window.localStorage.getItem(LOCALSTORAGE_LOGIN_KEY));

    return data ? data.expiry : null;
}
