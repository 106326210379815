
import { GOOGLE_ANALYTICS_ID } from 'data/config/config';
import isOnline                from 'src/core/util/isOnline';
import { get as getProfile } from 'src/core/Profile';

const LOG_PREF = '[GoogleAnalyticsHelper] ';


// let isReady = false;


/**
 * Indicate if Google Analytics is used
 * @return {boolean}
 */
export const isEnabled = () => {
    return typeof GOOGLE_ANALYTICS_ID === 'string'
            && GOOGLE_ANALYTICS_ID.length > 0;
};


/**
 * Persist client id through sessions
 * @param  {string}
 * @return {string}
 */
const persistence = (() => {

    const STORAGE_CID_KEY = 'ua-cid';

    function setClientId(value) {
        localStorage.setItem(STORAGE_CID_KEY, value);
    }

    const getClientId = () => localStorage.getItem(STORAGE_CID_KEY);

    return {
        setClientId,
        getClientId,
    };
})();


/**
 * Manage the queue of pageview, events, etc to send
 */
const queue = (() => {

    const SEND_INTERVAL = 1000; // ms

    let _queue = [],
        processingInterval;

    /**
     * Send everything queued
     */
    function _empty() {
        if (processingInterval) {
            // Already processing
            return;
        }
        console.debug(LOG_PREF+'Processing queue');

        processingInterval = window.setInterval(() => {
            if (_queue.length) {
                let appType = global.isCordovaContext ? 'MobileApp' : 'WebApp';
                window.ga('set', 'dimension1', appType);
                let profile = getProfile();
                window.ga('set', 'dimension2', profile);
                window.ga('send', _queue.pop());
            } else {
                window.clearInterval(processingInterval);
                processingInterval = null;
            }
        }, SEND_INTERVAL);
    }

    /**
     * Process queue only if device is online
     */
    function empty() {
        if (processingInterval) {
            // Already processing
            return;
        }
        isOnline(result => {
            if (result) {
                _empty();
            }
        });
    }

    /**
     * @param {object} opts
     */
    function add(opts) {
        if (typeof opts === 'object') {
            console.debug(LOG_PREF+'Add to queue');
            _queue.push(opts);
            // Process queue
            empty();
        } else {
            console.error(LOG_PREF+'Cannot add argument to queue as object is expected but got: '+(typeof opts));
        }
    }

    return {
        add,
        empty,
    };

})();


/**
 * Load GA library and initialize GA tracker
 */
export function init() {

    if (isEnabled()) {

        // Fallback
        if (!window.ga) {
            console.info(LOG_PREF+'Fallback, loading library via www');

            // eslint-disable-next-line
            (function(i,s,o,g,r,a,m){i['GoogleAnalyticsObject']=r;i[r]=i[r]||function(){(i[r].q=i[r].q||[]).push(arguments)},i[r].l=1*new Date();a=s.createElement(o), m=s.getElementsByTagName(o)[0];a.async=1;a.src=g;m.parentNode.insertBefore(a,m) })(window,document,'script','https://www.google-analytics.com/analytics.js','ga');
        }

        // Init
        let options = {
            trackingId  : GOOGLE_ANALYTICS_ID,
            cookieDomain: 'none',
            storage     : 'none',
        };
        if (global.isCordovaContext && window.device) {
            options.clientId = persistence.getClientId() ||  window.device.uuid;
        }
        else {
            options.clientId = persistence.getClientId();
        }
        window.ga('create', options);

        window.ga('set', 'appName', process.env.APP_NAME);
        window.ga('set', 'appVersion', process.env.APP_VERSION);
        window.ga('set', 'buildVersion', process.env.BUILD_VERSION);

        // Allow if current page protocol is different from http(s):// (needed for cordova)
        window.ga('set', 'checkProtocolTask', null);

        // Listen to ready callback
        window.ga(defaultTracker => {
            // isReady = true;
            console.info(LOG_PREF+'Google Analytics tracker is ready. Client id: '+defaultTracker.get('clientId'));
            persistence.setClientId(defaultTracker.get('clientId'));

            queue.empty();
        });
    }
    else {
        console.warn(LOG_PREF+'No tracking id configured, so module is disabled');
    }
}


export const CATEGORIES = {
    intersticiel: 'intersticiel',
    linkClicked : 'linkClicked',
    mobigeo     : 'mobigeo',
    appointment : 'appointment',
};

export const ACTIONS = {
    [CATEGORIES.intersticiel]: {
        view: 'view',
    },
    [CATEGORIES.linkClicked]: {
        url: 'url',
    },
    [CATEGORIES.mobigeo]: {
        goTo       : 'goTo',
        error      : 'error',
        enterSite  : 'enterSite',
        setPseudo  : 'setPseudo',
        createGroup: 'createGroup',
        joinGroup  : 'joinGroup',
        quitGroup  : 'quitGroup',
        favoriteCreated: 'favoriteCreated',
        favoriteShared : 'favoriteShared',
    },
    [CATEGORIES.appointment]: {
        success: 'success',
        failure: 'failure',
    },
};

const HIT_TYPES = {
    pageview: 'pageview',
    event   : 'event',
    social  : 'social',
    timing  : 'timing',
};


export function sendPageView(page) {
    queue.add({
        hitType: HIT_TYPES.pageview,
        page   : page,
    });
}

export function sendEvent(category, action, label, value) {
    queue.add({
        hitType      : HIT_TYPES.event,
        eventCategory: category,
        eventAction  : action,
        eventLabel   : label,
        eventValue   : value
    });
}
