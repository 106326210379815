
import React from 'react';
import PropTypes from 'prop-types';

import './Loader.scss';


const Loader = props => (

    <div className={ ['simple-loader', props.theme].join(' ') }>
        <div>{ props.labels.common.loading }</div>
    </div>

);

Loader.propTypes = {
    labels: PropTypes.object.isRequired,
    theme : PropTypes.string,
};

export default Loader;