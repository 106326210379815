
import React                  from 'react';
import PropTypes              from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect }            from 'react-redux';

import Loader from 'src/components/loader/Loader';

import * as actions from 'src/store/actions';


import './FullLoader.scss';


export const COMPONENT_KEY = 'FullLoader';
export const CONTAINER_DOM_ID = 'full-loader-container';


/**
 * Display a full screen loader, overlaying everything else
 * @param  {object} props
 */
const FullLoader = (props) => (
    props.isDisplayed === true
        ? <div id="full-loader" className={props.theme}><Loader labels={props.labels} /></div>
        : null
);


FullLoader.propTypes = {
    labels     : PropTypes.object.isRequired,
    isDisplayed: PropTypes.bool.isRequired,
};

const mapStateToProps = (state, ownProps) => state[COMPONENT_KEY];
const mapDispatchToProps = dispatch => ({ actions: bindActionCreators(actions, dispatch) });

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(FullLoader);
