import { LIST_PAGE_KEY } from 'src/pages/pagesKeys.js';

import ListPage        from 'src/pages/list/ListPage';
import listPageReducer from 'src/pages/list/listPageReducer';

export default {
    key      : LIST_PAGE_KEY,
    path     : '/list',
    component: ListPage,
    elId     : 'list-page',
    getReduxReducer: (state, action) => listPageReducer(state[LIST_PAGE_KEY], action),
};
