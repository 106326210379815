
import ons from 'onsenui';

import { START_LOCATION_ON_APP_BOOT } from 'data/config/mapConfig';

import { get as getLabels } from 'src/core/Lang';
import { getDisclaimer } from 'src/core/config-json/ConfigJsonManager';
import { isAndroid } from 'src/core/util/browser';


const LOG_PREF = '[LocationHelper] ';

let blePlugin;


/**
 * Persist the status of geolocation has the user set it
 */
export const StoredManualLocationStatus = (function() {

    const LOCAL_STORAGE_KEY = 'manually-turned-off-location';

    const STATUS = {
        ENABLED : 'enabled',
        DISABLED: 'disabled',
    };

    function setEnabled() {
        _set(STATUS.ENABLED);
    }
    function setDisabled() {
        _set(STATUS.DISABLED);
    }
    function _set(value) {
        localStorage.setItem(LOCAL_STORAGE_KEY, value);
    }

    function hasBeenDisabled() {
        return localStorage.getItem(LOCAL_STORAGE_KEY) === STATUS.DISABLED;
    }

    return { setEnabled, setDisabled, hasBeenDisabled };
})();


/**
 * Persist the result of the disclaimer popup (accepted/denied)
 */
let StoredDisclaimerValue = (function() {

    const LOCAL_STORAGE_KEY = 'location-disclaimer';

    const DISCLAIMER_VALUES = {
        accepted: 'accepted',
        denied  : 'denied',
    };

    function isAccepted() {
        return localStorage.getItem(LOCAL_STORAGE_KEY) === DISCLAIMER_VALUES.accepted;
    }

    function isDenied() {
        return localStorage.getItem(LOCAL_STORAGE_KEY) === DISCLAIMER_VALUES.denied;
    }

    function setAccepted() {
        localStorage.setItem(LOCAL_STORAGE_KEY, DISCLAIMER_VALUES.accepted);
    }

    function setDenied() {
        localStorage.setItem(LOCAL_STORAGE_KEY, DISCLAIMER_VALUES.denied);
    }

    return { isAccepted, isDenied, setAccepted, setDenied };
})();


export function stopLocation() {
    MobiGeo.Location.stop();
}


export function shouldAutoStart() {
    const
        disclaimerNotDenied = StoredDisclaimerValue.isDenied() !== true,
        locationNotManuallyStopped = StoredManualLocationStatus.hasBeenDisabled() !== true;

    console.log(LOG_PREF+'Start on app boot: '+START_LOCATION_ON_APP_BOOT
                    +' # Disclaimer not denied: '+disclaimerNotDenied
                    +' # Location not manually stoppped: '+locationNotManuallyStopped);

    return START_LOCATION_ON_APP_BOOT && disclaimerNotDenied && locationNotManuallyStopped;
};


/**
 * Auto start location service, depending on configuration
 */
export function autoStartLocation() {
    if (global.isCordovaContext && cordova.plugins) {
        blePlugin = cordova.plugins.BluetoothStatus;
    }
    if (blePlugin) {
        blePlugin.initPlugin();
    }

    if (shouldAutoStart()) {
        startLocation();
    }
}


function _proceed() {
    console.log(LOG_PREF+'Starting location service');

    if (isAndroid() && blePlugin && blePlugin.BTenabled !== true) {
        // Auto activate BLE
        blePlugin.enableBT();
    }

    MobiGeo.Location.start();
}

let isDisclaimerDisplayed;

/**
 * Start location service
 */
export function startLocation() {
    if (global.isCordovaContext && MobiGeo && MobiGeo.Location) {

        if (StoredDisclaimerValue.isAccepted()) {
            // Disclaimer already accepted
            _proceed();

        } else {
            let disclaimer = getDisclaimer();

            // If no disclaimer has been configured, directly start location
            if (!disclaimer || !disclaimer.text) {
                _proceed();

            } else if (isDisclaimerDisplayed !== true) {
                // Show modal
                ons.notification.confirm(disclaimer.text, {
                    title: disclaimer.title,
                    buttonLabels: [getLabels().common.deny, getLabels().common.accept],
                    callback: function(selectedBtnIndex) {
                        isDisclaimerDisplayed = false;

                        switch (selectedBtnIndex) {

                            // Denied
                            case 0:
                                StoredDisclaimerValue.setDenied();
                                break;

                            // Accepted
                            case 1:
                                StoredDisclaimerValue.setAccepted();
                                _proceed();
                                break;

                            default:
                        }
                    },
                });
                isDisclaimerDisplayed = true;
            }
        }
    }
};
