// Default value. Set to 0 to not auto-dismiss
export var AUTO_DISMISS = {
  success: 4,
  info: 4,
  warning: 6,
  error: 6
};
/**
 * @see https://github.com/igorprado/react-notification-system#styles
 * @see https://github.com/igorprado/react-notification-system/blob/master/src/styles.js
 */

export var STYLE = {
  // Notification item
  NotificationItem: {
    // Override the notification item
    DefaultStyle: {
      // Applied to every notification, regardless of the notification level
      fontSize: '1.1em'
    },
    info: {
      borderTop: '2px solid #949FA7',
      backgroundColor: 'white'
    }
  },
  // Notifications container
  Containers: {
    DefaultStyle: {
      padding: '30px 10px 10px 10px'
    }
  }
};