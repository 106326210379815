module.exports = {
  name: 'EPHJ2019',
  client: 'EPHJ',
  description: 'Projet MobiGeo pour l\'application EPHJ 2019',
  pluginId: 'mobigeo-plugin-ephj2019',
  version: '1.0.0',
  validLangs: ['en', 'fr'],
  native: {
    version: '4',
    author: 'Mobile-Spot',
    backgroundColor: '0xffffff',
    // iosAppStoreUrl: 'https://itunes.apple.com/app/paris-air-show/id628105892',
    iosBuildTarget: '10.0',
    // Android
    // 'androidapp-id': 'TODO', // unused?
    // playStoreUrl: 'https://play.google.com/store/apps/details?id=com.mobilespot.ephj',
    androidFadeSplashScreenDuration: 750,
    androidSigning: {
      keyAlias: 'mobile-spot',
      keyStorePassword: 'jee1Uu0Hieloh7bi'
    },
    // Web app to wrap in the cordova app ('ionic/www', 'simple', 'test', 'demo', 'app-react' - see cordova/testapps/)
    app: 'app-react'
  },
  // List the folders of the data bundles to include for this project
  data: {
    default: 'dem7eKah'
  },
  // 'location', 'location.geogroup', 'location.geopush', 'map.route', 'assets.update', 'poi.aroundme', 'favorites'
  features: [],
  location: {
    mapping: {},
    registering: {
      location: false,
      geofencing: false
    }
  },
  undeliveredFolders: ['source', 'exports'],
  cordova: true,
  crypto: false,
  // @see doc on google drive: R&D/Migration WK webview: https://docs.google.com/spreadsheets/d/1sIfm3LgBq1Zgp7LH2-mKSD1vrL6VphNR1mkiHZ73zHM/edit?usp=drive_web&ouid=101003723454836243359
  // @see cordova/bin/addIosCustomUrlScheme.js
  // @see app-react/src/core/data-and-assets/FileSystemHelper.js `getPath`
  // @see app-react/src/core/util/FetchHelper.js
  customFileUrlScheme: 'msfile',
  cordova_plugins: {
    mobigeo_plugin_dependencies: [{
      id: 'cordova-plugin-file-transfer'
    }],
    app_dependencies: [{
      id: 'cordova-android-support-gradle-release'
    }, // Solve android support dependencies (versions conflict)
    {
      id: 'cordova-plugin-whitelist'
    }, {
      id: 'cordova-plugin-inappbrowser'
    }, {
      id: 'cordova-plugin-splashscreen'
    }, {
      id: 'cordova-plugin-x-socialsharing'
    }, // for warning message: 'config file undefined requested for changes not found ...'
    // see: https://github.com/Pushwoosh/pushwoosh-phonegap-plugin/issues/297#issuecomment-418101837
    // { id: 'pushwoosh-cordova-plugin' },
    {
      id: 'cordova-plugin-wkwebview-engine'
    }, {
      id: 'cordova-plugin-wkwebview-file-xhr'
    }, {
      id: 'cordova-plugin-wkwebview-openblank'
    }, {
      id: 'cordova-plugin-urloverride'
    }, {
      id: '../src-app-plugins/cordova-plugin-mobilespot-app',
      variable: 'HOST',
      varValue: 'web.url'
    }, {
      id: '../src-app-plugins/notificationManager'
    }, {
      id: '../src-app-plugins/pushwoosh-cordova-plugin'
    }]
  },
  geoGroup: {}
};