
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import config from 'data/config/config';

import Url from 'src/components/url/Url';

import './MobilespotButton.scss';


class MobilespotButton extends PureComponent {

    render() {
        return (
            <div
                className="mobilespot-button content-font"
                title={ this.props.labels.promoMs }>

                <Url href={ config.MOBILESPOT_WWW } label={ '© Mobile-Spot' } />
            </div>
        );
    }
}

MobilespotButton.propTypes = {
    labels: PropTypes.object.isRequired,
};

export default MobilespotButton;