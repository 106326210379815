import { FAVORITES_PAGE_KEY } from 'src/pages/pagesKeys.js';

import FavoritesPage        from 'src/pages/favorites/FavoritesPage';
import favoritesPageReducer from 'src/pages/favorites/favoritesPageReducer';

export default {
    key      : FAVORITES_PAGE_KEY,
    path     : '/favorites',
    component: FavoritesPage,
    elId     : 'favorites-page',
    getReduxReducer: (state, action) => favoritesPageReducer(state[FAVORITES_PAGE_KEY], action),
};
