import React from 'react'
import PropTypes from 'prop-types'
import './Checkboxes.scss';

class Checkboxes extends React.Component {
    handleChange = e => {
        const { values, lang, q_choices } = this.props
        const itemKey = e.target.name
        const isChecked = e.target.checked
        const ctrlArray = values || []
        const checkMap = new Map(ctrlArray.map(ch => [ch.choice, { value: true, key: ch.choice }]))
        checkMap.set(itemKey, { value: isChecked, key: itemKey })
        const choicesArray = [...checkMap.values()]
            .filter(item => item.value)

        const multiple = choicesArray.map(c => ({
            choice: c.key,
            choiceText: q_choices[c.key]['en'] ? q_choices[c.key]['en'] : q_choices[c.key][lang]
        }))

        this.props.onChange(multiple)
    }

    render() {
        const { items, values, lang } = this.props
        const ctrlArray = values || []
        const data = new Map(ctrlArray.map(ch => [ch.choice, { value: true, key: ch.choice }]))

        return (
            <div>
                {
                     items.map(item => {
                        const checked = data.get(item.key) && data.get(item.key).value
                        return (
                            <div key={item.key} className={`checkbox ${checked ? 'checkbox-active' : ''}`}>
                                <input
                                    type='checkbox'
                                    name={item.key}
                                    checked={checked}
                                    onChange={this.handleChange}
                                    className='checkbox-input'
                                />
                                <div className="checkbox-content">
                                    <div className="checkbox-title">{item[lang]}</div>
                                    <div className="checkbox-toggle">
                                        {checked && <div></div>}
                                    </div>
                                </div>
                            </div>
                        )
                    })
                }
            </div>
        )
    }
}

Checkboxes.propTypes = {
    onChange: PropTypes.func.isRequired,
    items: PropTypes.array.isRequired,
    values: PropTypes.array,
    lang: PropTypes.string,
    q_choices: PropTypes.object
}

export default Checkboxes
