
import ons from 'onsenui';

import getInAppBrowserOptions from 'data/config/inAppBrowserOptions';
import { isAndroid } from 'src/core/util/browser';
import * as Lang from 'src/core/Lang';
import { get as getProfile } from 'src/core/Profile';
import { parseUrl, queuedNavigation } from 'src/core/navigation/Router';
import { notificationHandler } from 'src/pages/inbox/InboxService';

const LOG_PREF = '[CordovaHelper] ';


/**
 * @param {object} actions (store binded)
 */
export function init(actions) {

    if (global.isCordovaContext !== true) {
        return;
    }

    ons.ready(() => {
        // ANDROID BACK BUTTON BEHAVIOUR
        ons.setDefaultDeviceBackButtonListener(e => {
            actions.navigateBack();
        }, false);
    });


    // IN APP BROWSER
    if (cordova.InAppBrowser) {
        const nativeOpen = window.open
        window.open = function(url, target) {
            let options = getInAppBrowserOptions(
                isAndroid() ? 'android' : 'ios',
                Lang.get(),
            );

            // target option to bypass inappbrowser
            if (target === '_native') {
              nativeOpen(url, '_blank');
            } else {
              cordova.InAppBrowser.open(url, target, options);
            }
        };
    }

    /**
     * Handle when app is opened via an url
     * e.g http://ph-dev.mobile-spot.com/?/service&originalId=5c442084-9ee7-e811-80d9-005056ae0696&lang=fr&profile=default
     */
    if (window.mobilespotApp && typeof window.mobilespotApp.getIntent === 'function') {

        function getIntentAndNavigate() {
            window.mobilespotApp.getIntent(
                function getIntentSuccessCb(intentUri) {
                    if (intentUri) {
                        console.log(LOG_PREF+'App started with intent url: ' + intentUri);
                        queuedNavigation.set(() => parseUrl(intentUri));
                    }
                },
                function getIntentFailureCb(...args) {
                    console.error(LOG_PREF+'Failed to execute window.mobilespotApp.getIntent', args);
                }
            );
        }

        document.addEventListener('onIntent', function(event) {
            console.info('onIntent event received ;)', event);
            getIntentAndNavigate();
        });

        // Check if app has been started with query parameters
        getIntentAndNavigate();
    }


    if (hasPendingNotificationsAPI()) {
        document.addEventListener('onNotification', function() {
            console.log('Received onNotification event');
            getPendingNotifications();
        });

        // Get pending notifications on app startup
        getPendingNotifications();
    }
};

export function hasPendingNotificationsAPI() {
    return window.notificationManager && typeof window.notificationManager.getPendingNotifications === 'function';
}

export function getPendingNotifications() {
    if (!getProfile()) {
        // Profile needs to be set first because the user can navigate from the notification dialog
        // @see cordovaReducerAndMiddleware.js
        return;
    }
    if (hasPendingNotificationsAPI()) {
        window.notificationManager.getPendingNotifications(
            function getPendingNotificationsSuccess(sNotifs) {
                console.log('getPendingNotifications', sNotifs);
                var notifs;
                if (sNotifs) {
                    var parsed = JSON.parse(sNotifs);
                    if (parsed && parsed.notifications) {
                        notifs = parsed.notifications;
                    }
                }

                if (Array.isArray(notifs) && notifs.length) {
                    // NB: array contains notifications in reverse chronological order
                    notifs.reverse().forEach(notificationHandler);
                }
            },
            function getPendingNotificationsError() {
                console.log('getPendingNotifications error', arguments);
            },
        );
    }
}
