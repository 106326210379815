
// Libs
import React        from 'react';
import PropTypes    from 'prop-types';
import { ListItem } from 'react-onsenui';

const GenericMenuItem = ({ entry, associatedPageKey, closeMenu, isDisabled }) => {

    // CONTAINER
    let hasContainerStyle = entry.style && typeof entry.style.container === 'object' && entry.style.container !== null,
        contAttrs = {},
        contClasses = [];

    // css
    if (hasContainerStyle && entry.style.container.style) {
        contAttrs.style = entry.style.container.style;
    }
    //if (isDisabled === true) {
    //    contClasses.push('menu-item-disabled');
    //}
    if (hasContainerStyle && entry.style.container.className) {
        contClasses.push(entry.style.container.className);
    }
    contAttrs.className = contClasses.join(' ');

    // is tappable ?
    contAttrs.tappable = isDisabled !== true;

    // onClick handler
    contAttrs.onClick = isDisabled === true ? null : () => {
        closeMenu(associatedPageKey);
        entry.action(associatedPageKey);
    };


    // ICON
    let hasIconStyle = entry.style && typeof entry.style.icon === 'object' && entry.style.icon !== null,
        iconClasses = ['menu-icon'];

    if (hasIconStyle && entry.style.icon.className) {
        iconClasses.push(entry.style.icon.className);
    }


    return (
        <ListItem {...contAttrs}>
            <div className="menu-item content-font">
                <span className={ iconClasses.join(' ') }
                      style={ hasIconStyle && entry.style.icon.style ? entry.style.icon.style : null }
                      >{ hasIconStyle && typeof entry.style.icon.webfont === 'string' ? entry.style.icon.webfont : null }</span>
                <span className="menu-text" dangerouslySetInnerHTML={{ __html: entry.label }} />
            </div>
        </ListItem>
    );
};

GenericMenuItem.propTypes = {
    entry    : PropTypes.object.isRequired,
    closeMenu: PropTypes.func.isRequired,
    // Indicate on which page the menu instance is attached
    associatedPageKey: PropTypes.string.isRequired,
    isDisabled: PropTypes.bool,
};

export default GenericMenuItem;
