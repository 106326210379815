
import io from 'socket.io-client';

import config from 'data/config/config';

const LOG_PREF = '[RealTimeService] ';


let socket,
    registeredEvents = [];


let actions;
export function setBindedActions(_actions) {
    actions = _actions;
};


/**
 * Start WebSocket connection
 * @param {string} query
 */
export function connect(query) {
    // Close any existing connection
    disconnect();

    socket = io(config.REAL_TIME.URL + (query ? '/?'+query : ''));

    socket.on('connect', function(){
        console.log(LOG_PREF+'WS connection opened');
        //Ui.setConnected();
        actions.realTimeConnected();
    });

    socket.on('disconnect', function(){
        console.log(LOG_PREF+'WS connection closed');
        //Ui.setDisconnected();
        actions.realTimeDisconnected();
    });

    // Register custom events
    registeredEvents.forEach(function(handlerData) {
        _registerEvent(handlerData.eventName, handlerData.callback);
    });
};



/**
 * Allows random modules to listen to real-time events
 */
export function registerEvent(eventName, callback) {
    registeredEvents.push({
        eventName,
        callback,
    });

    _registerEvent(eventName, callback);
}

function _registerEvent(eventName, callback) {
    if (socket) {
        socket.on(eventName, function(data) {
            let parsedData;
            try {
                parsedData = JSON.parse(data);
            } catch(e) {
                console.warn(LOG_PREF+'data is not parseable', data);
            }
            callback(parsedData || data);
        });
    }
}


/**
 * Terminate WebSocket connection
 */
export function disconnect() {
    if (socket) {
        socket.close();
    }
};


/**
 * Broadcast to server
 * @param {string} eventName
 * @param {*} data
 */
export function emit(eventName, data) {
    socket.emit(eventName, data);
};
