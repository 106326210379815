
import React     from 'react';
import PropTypes from 'prop-types';


const PhoneRow = props => (
    !props.phone ? null :
        <div className="free-row">
            <div className="prop-img">
                <div className="prop-left"><span className="fa fa-phone" /></div>
                <div
                    className="prop-right"
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center'
                    }}
                >
                    <a className="link color-link-blue" href={'tel:'+props.phone}>{props.phone}</a>
                </div>
            </div>
        </div>
);

PhoneRow.propTypes = {
    phone: PropTypes.string,
};

export default PhoneRow;
