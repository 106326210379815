import {
    SHOW_PROFILE_DIALOG,
    HIDE_PROFILE_DIALOG,
    UPDATE_PROFILE,
    LANG_CHANGED
} from 'src/store/actionTypes';

import {
    get as getLabels,
} from 'src/core/Lang';

import {
    langChanged,
    updateObject,
} from 'src/store/reducers/commons';

const getDefaultState = () => ({
    labels : getLabels(),
    isOpen : false,
    identification: null,
    error  : false,
    profile: {}
});


const _toggleDialog = (state, action, value) => updateObject(state, { isOpen: value });

const _updateProfile = (state, action) => (
    updateObject(state, {
        profile: action.profile,
    })
)


export default (state = getDefaultState(), action) => {
    switch (action.type) {
        case LANG_CHANGED: return langChanged(state, action);
        case SHOW_PROFILE_DIALOG: return _toggleDialog(state, action, true);
        case HIDE_PROFILE_DIALOG: return _toggleDialog(state, action, false);
        case UPDATE_PROFILE: return _updateProfile(state, action);
        default: return state;
    }
};