
import React from 'react';
import PropTypes from 'prop-types';
import Switch from 'react-switch';
import { ListItem } from 'react-onsenui';

import { LOCATION_BUTTON_PROPS } from 'data/config/menuConfig';

import { startLocation, stopLocation, StoredManualLocationStatus } from 'src/pages/map/locationHelper';


class ToggleLocationItem extends React.PureComponent {

    toggleLocation = event => {
        event.preventDefault();

        if (this.props.isEnabled === true) {
            StoredManualLocationStatus.setDisabled();
            stopLocation();
        } else {
            StoredManualLocationStatus.setEnabled();
            startLocation();
        }
    }

    render() {
        if (!global.isCordovaContext) {
            return null;
        }
        return (
            <ListItem className="list-item-toggle-loc" tappable={true} onClick={this.toggleLocation}>
                <div className="menu-item content-font">

                    {/* see:
                         https://github.com/markusenglund/react-switch
                         https://react-switch.netlify.com/
                     */}
                    <Switch
                        onChange={this.toggleLocation}
                        checked={this.props.isEnabled}
                        className="react-switch"
                        {...LOCATION_BUTTON_PROPS} />

                    <div className="list-item-toggle-loc-label">
                        {this.props.labels.location.feature}
                    </div>
                </div>
            </ListItem>
        );
    }
};

ToggleLocationItem.propTypes = {
    labels   : PropTypes.object.isRequired,
    isEnabled: PropTypes.bool.isRequired,
};

export default ToggleLocationItem;