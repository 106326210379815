
import React     from 'react';
import PropTypes from 'prop-types';

import './CTAButton.scss';

/**
 * Call-to-action button
 * @param  {object} props
 */
const CTAButton = props => (
    <div className="cta-btn-container">
        <div
            className={'cta-btn'+(props.isEnabled === false ? ' cta-btn-disabled' : '')}
            onClick={props.isEnabled === false ? null : props.action}>{props.label}</div>
    </div>
);

CTAButton.propTypes = {
    label : PropTypes.string.isRequired,
    action: PropTypes.func.isRequired,
    isEnabled: PropTypes.bool,
};

export default CTAButton;