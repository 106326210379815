
import React     from 'react';
import PropTypes from 'prop-types';

import './NoResult.css';


const NoResult = props => <div className="no-result">{props.customLabel || props.labels.common.noResult}</div>;

NoResult.defaultTypes = {
    customLabel: null
}

NoResult.propTypes = {
    customLabel: PropTypes.string,
    labels: PropTypes.object.isRequired,
};

export default NoResult;