import { SEARCH_PAGE_KEY } from 'src/pages/pagesKeys.js';

import SearchPage        from 'src/pages/search/SearchPage';
import searchPageReducer from 'src/pages/search/searchPageReducer';

export default {
    key      : SEARCH_PAGE_KEY,
    path     : '/search',
    component: SearchPage,
    elId     : 'search-page',
    getReduxReducer: (state, action) => searchPageReducer(state[SEARCH_PAGE_KEY], action),
};
