
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { AlertDialog } from 'react-onsenui';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { iosHackScrollTo0 } from 'src/core/util/browser';

import * as actions from 'src/store/actions';

import './ProfileDialog.scss';
import '../genericModal.scss';


export const COMPONENT_KEY = 'ProfileDialog';
export const CONTAINER_DOM_ID = 'profile-dialog-container';


const LOG_PREF = '[ProfileDialog] ';


const DEFAULT_DIALOG_PROPS = {
    isCancelable: true,
    animation: 'default', // or 'none', no other choice sadly
};


const FIELDS = {
    title: {
        name: 'title',
        type: 'text',
    },
    firstName: {
        name: 'firstName',
        type: 'text',
        required: true,
    },
    lastName: {
        name: 'lastName',
        type: 'text',
        required: true,
    },
    organisation: {
        name: 'organisation',
        type: 'text',
    },
    position: {
        name: 'position',
        type: 'text',
        required: true,
    },
    email: {
        name: 'email',
        type: 'email',
        required: true,
        pattern: /^[\w._+-]+@[\w.-]+\.[A-Za-z]{2,64}$/
    },
    phone: {
        name: 'phone',
        type: 'tel',
        required: true,
        pattern: /^[ \.\+0-9]*$/,
    },
};

/**
 * Display a dialog (modal) allowing user to read and update its profile information
 * @param  {object} props
 */
class ProfileDialog extends Component {

    constructor(props) {
        super(props);

        // Initialize state for controlled inputs
        let initialState = {};
        Object.keys(FIELDS).forEach(function(fieldName) {
            initialState[fieldName] = '';
        });
        this.state = initialState;
    }

    dialogProps = () => Object.assign({}, DEFAULT_DIALOG_PROPS, {
        isOpen  : this.props.isOpen,
        onCancel: this.props.actions.hideProfileDialog, // important, to update `isOpen` value in all cases
    })

    componentWillReceiveProps(newProps) {
        if (this.props.profile !== newProps.profile && newProps.profile) {

            let newValues = {};
            Object.keys(newProps.profile).forEach(function(fieldName) {
                // Take only used fields
                if (FIELDS[fieldName]) {
                    newValues[fieldName] = newProps.profile[fieldName];
                }
            });
            this.setState(newValues);
        }
    }

    onChange = event => {
        let el = event.target;
        this.setState({
            [el.getAttribute('name')]: el.value,
        });
    }

    /**
     * Check error for a single field
     * @return {boolean}
     */
    hasError(name, value) {
        if (!FIELDS[name]) {
            console.error(LOG_PREF+`Handling undeclared field '${name}' (should not happen)`);
            return false;
        }

        // value is a string or undefined
        if ((!value || value.length === 0) && FIELDS[name].isRequired === true) {
            return true;
        }
        if (FIELDS[name].pattern && FIELDS[name].pattern.test(value) !== true) {
            return true;
        }
        return false;
    }

    /**
     * Check error for all field
     * @return {boolean}
     */
    hasFormError() {
        let isOk = true;

        Object.keys(this.state).forEach(function(fieldName) {
            isOk = isOk && this.hasError(fieldName, this.state[fieldName]) !== true;
        }.bind(this));

        return isOk !== true;
    }

    /**
     * Handle submission
     */
    handleUpdateClicked = () => {
        // if (this.hasFormError()) {
            // TODO?
            // return;
        // }

        this.props.actions.updateProfile(this.state);
        this.props.actions.hideProfileDialog();
    }


    render() {
        let dialogLabels = this.props.labels.profileDialog;

        return (
            <AlertDialog {...this.dialogProps()}>
                <div id="profile-dialog" className="generic-modal">
                    <div className="title-font">{dialogLabels.title}</div>
                    <div className="content-font">
                        <form>
                            {
                                Object.keys(FIELDS).map(fieldName => (
                                    <input
                                        key={fieldName}
                                        name={fieldName}
                                        className={'generic-modal-input' + (this.hasError(fieldName, this.state[fieldName]) ? ' generic-form-error' : '')}
                                        //ref={this.setElement}
                                        placeholder={dialogLabels.fields[fieldName]+( FIELDS[fieldName].required ? ' *' : '')}
                                        type={FIELDS[fieldName].type}
                                        value={this.state[fieldName]}
                                        onBlur={iosHackScrollTo0}
                                        onChange={this.onChange} />
                                ))
                            }
                        </form>
                        <div className="generic-modal-btn-container">
                            <div className="generic-modal-btn" onClick={this.props.actions.hideProfileDialog}>{this.props.labels.common.cancel}</div>
                            <div className="generic-modal-btn cta-modal-btn" onClick={this.handleUpdateClicked}>{this.props.labels.common.confirm}</div>
                        </div>
                    </div>
                </div>
            </AlertDialog>
        );
    }

};

ProfileDialog.propTypes = {
    isOpen : PropTypes.bool.isRequired,
    labels : PropTypes.object.isRequired,
    actions: PropTypes.object.isRequired,
    profile: PropTypes.object,
};

const mapStateToProps = (state, ownProps) => state[COMPONENT_KEY];
const mapDispatchToProps = dispatch => ({ actions: bindActionCreators(actions, dispatch) });

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ProfileDialog);
