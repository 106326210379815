// @see https://github.com/cubiq/iscroll
export var ISCROLL_OPTIONS = {
  deceleration: 0.001,
  HWCompositing: false,
  mouseWheel: true,
  scrollbars: false
};
/* When DOM is updated, iScroll needs to refresh its measures
 * This value here indicates how much time after DOM update the refresh is performed
 *
 * @see § "Mastering the refresh method" on github page
 * quote: "Consider that if you have a very complex HTML structure you may give the browser some more rest and raise the timeout to 100 or 200 milliseconds."
 */

export var REFRESH_DELAY = 250;