import { MAP_PAGE_KEY } from 'src/pages/pagesKeys.js';

import MapPage        from 'src/pages/map/MapPage';
import mapPageReducer from 'src/pages/map/mapPageReducer';

export default {
    mountOnce: true,
    key      : MAP_PAGE_KEY,
    path     : '/map',
    component: MapPage,
    elId     : 'map-page',
    getReduxReducer: (state, action) => mapPageReducer(state[MAP_PAGE_KEY], action),
    toggleMode  : 'visibility',
};
