
// conf
import {
    get as getHomeConfig,
    getStyle,
} from 'data/config/homeConfig'

import {
    CONFIG_JSON_LOADED,
    HAS_NAVIGATED,
    LANG_CHANGED,
    POLL_CONFIG_LOADED,
    PROFILE_CHANGED,
    SET_LOGIN_IDENTIFICATION,
    TOGGLE_LOCATION_STATUS,
    TOGGLE_MENU,
    UPDATE_PAGE_STATE,
    WINDOW_RESIZED,
} from 'src/store/actionTypes';

import {
    ADD_DUPLICATE_MESSAGE,
    ADD_MESSAGE,
} from 'src/pages/inbox/inboxReducer';

import {
    getPageCommonDefaultState,
    toggleLocationStatus,
    toggleMenu,
    updateObject,
    updatePageState,
    windowResized,
} from 'src/store/reducers/commons';

import { HOME_PAGE_KEY, INBOX_PAGE_KEY } from 'src/pages/pagesKeys';

import { get as getLabels } from 'src/core/Lang';

import { getCurrentPageKey } from 'src/core/navigation/CurrentRoute';
import { getAdConfig }    from 'src/core/config-json/ConfigJsonManager';
import { getPollConfig }  from 'src/core/polls/PollManager';
import { getOrientation } from 'src/core/util/DomTools';


function getDefaultState() {
    const
        commonDefaultState = getPageCommonDefaultState(HOME_PAGE_KEY),
        orientation = getOrientation();

    return Object.assign({}, commonDefaultState, {
        homeConfig : getHomeConfig(commonDefaultState.profile, commonDefaultState.labels.id, null, orientation),
        homeStyle  : getStyle(),
        orientation: orientation,
        inboxUnreadCount: 0,
    });
}


function _profileChanged(state, action) {

    // On app initialization, lang file is not loaded yet
    if (getLabels()) {
        let adConfig = getAdConfig(action.profile),
            pollConfig = getPollConfig(action.profile),
            homeConfig = getHomeConfig(action.profile, state.labels.id, adConfig, state.orientation);

        return updateObject(state, {
            profile   : action.profile,
            homeConfig: homeConfig,
            adConfig  : adConfig,
            pollConfig: pollConfig
        });
    }
    return state;
}

const _langChanged = (state, action) => (
    updateObject(state, {
        labels: action.labels,

        // config is lang dependant so refresh it too
        homeConfig: getHomeConfig(state.profile, action.labels.id, state.adConfig, state.orientation),
    })
);

function _windowResized(state, action) {
    let newState = windowResized(state, action);
    newState.homeConfig = getHomeConfig(state.profile, state.labels.id, state.adConfig, newState.orientation);
    return newState;
}

function _configJsonLoaded(state) {
    let adConfig = getAdConfig(state.profile);
    return updateObject(state, {
        adConfig  : adConfig,
        homeConfig: getHomeConfig(state.profile, state.labels.id, adConfig, state.orientation),
    });
}

function _pollConfigLoaded(state, action) {
    let pollConfig = getPollConfig(state.profile);
    return updateObject(state, {
        pollConfig  : pollConfig
    });
}

function _setLoginIdentification(state, action) {
    let isLoggedIn = !!action.identification;
    if (state.isLoggedIn !== isLoggedIn) {
        return updateObject(state, { isLoggedIn: isLoggedIn });
    }
    return state;
}


export default (state = getDefaultState(), action) => {
    switch (action.type) {

        case CONFIG_JSON_LOADED: return _configJsonLoaded(state);
        case POLL_CONFIG_LOADED: return _pollConfigLoaded(state, action);
        case LANG_CHANGED     : return _langChanged(state, action);
        case PROFILE_CHANGED  : return _profileChanged(state, action);
        case SET_LOGIN_IDENTIFICATION: return _setLoginIdentification(state, action);
        case TOGGLE_LOCATION_STATUS: return toggleLocationStatus(state, action);
        case TOGGLE_MENU      : return toggleMenu(state, action, HOME_PAGE_KEY);
        case UPDATE_PAGE_STATE: return updatePageState(state, action, HOME_PAGE_KEY);
        case WINDOW_RESIZED   : return _windowResized(state, action);

        // Inox count
        case ADD_DUPLICATE_MESSAGE:
        case ADD_MESSAGE:
            if (getCurrentPageKey() !== INBOX_PAGE_KEY) {
                return updateObject(state, { inboxUnreadCount: state.inboxUnreadCount+1 });
            } else {
                return state;
            }
        case HAS_NAVIGATED:
            if (action.pageKey === INBOX_PAGE_KEY) {
                return updateObject(state, { inboxUnreadCount: 0 });
            } else {
                return state;
            }

        default: return state;
    }
};
