
import { getUrl } from 'src/core/data-and-assets/DataAssetsUtil';
import * as Db    from 'src/core/data-and-assets/Db';


const LOG_PREF = '[MobiGeoAssetsProvider] ';
const forceNoCustomScheme = true;

/**
 * This allows MobiGeo to get its assets/data/... served by app-react
 * (instead of fetching its own local resources as in standalone mode)
 */
export default {

    // TODO: handle `dataset` property for multi-sites projects (e.g CA)

    map: (file, dataset) => {
        let assetPath = getUrl('files/maps/svg/'+file, null, null, forceNoCustomScheme);
        console.debug(LOG_PREF+'MobiGeo requires map file: '+file+' # result is: '+assetPath);
        return assetPath;
    },

    thumbnail: (pixelRatio, dataset) => {
        // NB: no ajax request on this asset (it is referenced through css)
        let assetPath = getUrl('files/maps/misc/x' + pixelRatio + '/thumbnail_0_0.jpg');
        console.debug(LOG_PREF+'MobiGeo requires thumbnail for pixel ratio '+pixelRatio+' # result is: '+assetPath);
        return assetPath;
    },

    data: (dataset) => Db.getData(),

    // TODO (when switching on the branch resulting from the merge ca-prod/planetintus):
    // provider renamed in 'routingGraphUrl'
    routingGraphUrl: (dataset) => {
        let assetPath = getUrl('files/maps/routing_graph/graph.json?' + (new Date().getTime()), null, null, forceNoCustomScheme);
        console.debug(LOG_PREF+'MobiGeo requires data_f # result is: '+assetPath);
        return assetPath;
    },

    mapConfig: (dataset) => {
        let assetPath = getUrl('files/maps/config/mapConfig.json', null, null, forceNoCustomScheme);
        console.debug(LOG_PREF+'MobiGeo requires mapConfig # result is: '+assetPath);
        return assetPath;
    },

    icon: (file, dataset) => {
        // NB: no ajax request on this asset (it is referenced through css)
        let assetPath = getUrl(file);
        // commented as it generates too much logs
        // console.debug(LOG_PREF+'MobiGeo requires icon: '+file+' # result is: '+assetPath);
        return assetPath;
    },

    coordinatesMapping: (dataset) => {
        let assetPath = getUrl('files/maps/config/coordinates-mapping.json', null, null, forceNoCustomScheme);
        console.debug(LOG_PREF+'MobiGeo requires coordinates mapping file # result is: '+assetPath);
        return assetPath;
    },

};
