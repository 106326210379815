// Libs
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import config from 'data/config/config';

// App modules
import { FAVORITES_PAGE_KEY, INBOX_PAGE_KEY, SEARCH_PAGE_KEY } from 'src/pages/pagesKeys';

import './HomeToolbar.scss';


class HomeToolbar extends PureComponent {

    getButtons = () => {
        const toolbar = [
            // Favorites page
            {   key: 1,
                iconClassName: 'icon-font',
                iconContent: 'e',
                label: this.props.labels.common.goToFavorites,
                action: () => { this.props.actions.navigate(FAVORITES_PAGE_KEY); }
            },
            // Inbox page
            {   key: 2,
                iconClassName: 'fa fa-envelope',
                label: this.props.labels.common.goToInbox,
//                badge: global.isCordovaContext ? this.props.inboxCount : null,
                badge: null,
                action: () => { this.props.actions.navigate(INBOX_PAGE_KEY); }
            },
            // Search page
            {   key: 3,
                iconClassName: 'fa fa-search',
                label: this.props.labels.common.goToSearch,
                action: () => { this.props.actions.navigate(SEARCH_PAGE_KEY); }
            },
            // Open menu
            {   key: 4,
                iconClassName: 'fa fa-bars',
                label: this.props.labels.common.openMenu,
                action: () => { this.props.actions.openMenu(this.props.associatedPageKey); }
            },
        ];

        // add Login modal
        if(config.LOGIN.FEATURE_ENABLED) toolbar.unshift(
            {   key: 0,
                iconClassName: 'fa fa-user ' + (this.props.isLoggedIn ? 'connected': ''),
                label: this.props.labels.common.login,
                action: () => { this.props.actions.showLoginDialog(); }
            },
        )
        return toolbar;
    }

    render() {
        return (
            <div id="home-toolbar">

                { this.getButtons().map(button => (
                    <div
                        key={button.key}
                        className="home-toolbar-button"
                        onClick={button.action}
                        title={button.label}>

                        { typeof button.badge === 'number' && <span className="home-toolbar-button-badge">{button.badge}</span> }

                        <span className={button.iconClassName}>{button.iconContent || ''}</span>
                    </div>
                )) }

            </div>
        );
    }
}

HomeToolbar.propTypes = {
    labels: PropTypes.object.isRequired,
    associatedPageKey: PropTypes.string.isRequired,
    actions: PropTypes.object.isRequired,
    isLoggedIn: PropTypes.bool,
    inboxCount: PropTypes.number,
};

export default HomeToolbar;