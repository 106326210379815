
import { DATA_TYPE_SERVICES } from 'data/config/dataConfig';

import { AUTOSHOW_POI_ON_MAP } from 'data/config/pagesTwoColumnsConfig';

import {
    CONFIG_JSON_LOADED,
    POLL_CONFIG_LOADED,
    ALL_FAVORITES_DELETED,
    DATA_UPDATED,
    ITEM_FETCHED,
    LANG_CHANGED,
    PROFILE_CHANGED,
    TOGGLE_FAVORITE,
    TOGGLE_LOCATION_STATUS,
    TOGGLE_MENU,
    UPDATE_PAGE_STATE,
} from 'src/store/actionTypes';

import {
    configJsonLoaded,
    pollConfigLoaded,
    dataUpdated,
    getPageCommonDefaultState,
    itemFetched,
    langChanged,
    profileChanged,
    setIsFavoriteFalse,
    toggleFavorite,
    toggleLocationStatus,
    toggleMenu,
    updatePageState,
} from 'src/store/reducers/commons';

import { SERVICE_PAGE_KEY } from 'src/pages/pagesKeys';


const USED_TABLES = [ DATA_TYPE_SERVICES ];


const getDefaultState = () => Object.assign({}, getPageCommonDefaultState(SERVICE_PAGE_KEY), {
    isPending: true,
    autoShowPoi: AUTOSHOW_POI_ON_MAP[DATA_TYPE_SERVICES] === true,
});


export default (state = getDefaultState(), action) => {
    switch (action.type) {

        case CONFIG_JSON_LOADED: return configJsonLoaded(state);
        case POLL_CONFIG_LOADED: return pollConfigLoaded(state, action);
        case ALL_FAVORITES_DELETED: return setIsFavoriteFalse(state);
        case DATA_UPDATED: return dataUpdated(state, action, USED_TABLES);
        case ITEM_FETCHED: return itemFetched(state, action, DATA_TYPE_SERVICES);
        case LANG_CHANGED: return langChanged(state, action);
        case UPDATE_PAGE_STATE: return updatePageState(state, action, SERVICE_PAGE_KEY);
        case PROFILE_CHANGED: return profileChanged(state, action, SERVICE_PAGE_KEY);
        case TOGGLE_FAVORITE: return toggleFavorite(state, action, DATA_TYPE_SERVICES);
        case TOGGLE_LOCATION_STATUS: return toggleLocationStatus(state, action);
        case TOGGLE_MENU: return toggleMenu(state, action, SERVICE_PAGE_KEY);

        default: return state;
    }
};
