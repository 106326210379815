
import React, { PureComponent }     from 'react';
import PropTypes from 'prop-types';

import { getUrl } from 'src/core/data-and-assets/DataAssetsUtil';

import './Description.scss';

class Description extends PureComponent {
    state = {
        opened: false
    }

    render () {
        const {
            description,
            logoFileName,
            preview,
            isLogoFullWidth,
            labels,
            onClick,
            className
        } = this.props
        const { opened } = this.state

        const hasDescription = typeof description === 'string' && description.length > 0,
            imgElement = logoFileName ? <img src={ getUrl('/'+logoFileName) } alt="logo" /> : null;

        const shouldPreview = preview && hasDescription && description.length > 400
        const previewDescription = !opened && shouldPreview
        const click = onClick && typeof onClick === 'function' ? onClick : () => {}

        return (
            <div className={`fiche-description ${className ? className : ''}`} onClick={click}>
                { (isLogoFullWidth || !hasDescription) && imgElement && <div className="logo img-full-width">{ imgElement }</div> }

                { hasDescription &&
                    <div className="prop-name">
                        {previewDescription && <div className="bottom-shadow"></div>}
                        <div className={`prop-description ${previewDescription ? 'preview' : ''}`}>
                            { !isLogoFullWidth && imgElement && <div className="logo img-left">{ imgElement }</div> }

                            { hasDescription &&
                                <p dangerouslySetInnerHTML={{ __html: description }} />
                            }
                        </div>
                        {previewDescription &&
                            <div className="btn-expand" onClick={() => this.setState({ opened: true })}>
                                {labels.common.seeMore}
                            </div>
                        }
                        {!previewDescription && shouldPreview &&
                            <div className="btn-expand" onClick={() => this.setState({ opened: false })}>
                                {labels.common.seeLess}
                            </div>
                        }
                    </div>
                }
            </div>
        )
    }
}

Description.propTypes = {
    labels: PropTypes.object,
    onClick: PropTypes.func,
    logoFileName: PropTypes.string,
    description : PropTypes.string,
    isLogoFullWidth: PropTypes.bool,
    preview: PropTypes.bool
};

export default Description;
