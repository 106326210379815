
/**
 * Module responsible for styling part of labels (e.g. searched string in search results)
 */
export default {

    /**
     * Return a regexp matching string to highlight
     * e.g. hightlight = 'comp', regexp will be '/([cC][oO][mM][pP])/g'
     * @param  {string} highlight
     * @return {RegExp}
     */
    getRegexp: (highlight) => {
        if (typeof highlight === 'string') {
            let str = '(',
                chars = highlight.split('');

            chars.forEach(char => {
                str = str.concat('['+char.toLowerCase()+char.toUpperCase()+']');
            });

            return new RegExp(str.concat(')'));
        }
    },

    /**
     * Add style to highlighted area
     * @param  {string} str
     * @param  {RegExp} highlightRegExp
     */
    apply: (str, highlightRegExp) => str.replace(highlightRegExp, '<b>$1</b>'),

};