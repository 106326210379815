var defaultConf = require('./config-default');

var NODE_BACKEND_DOMAIN = 'ephj2019-node-backend-dev.mobile-spot.com';
var NODE_WS_BAKEND_DOMAIN = 'ephj2019-node-backend-ws-dev.mobile-spot.com';
module.exports = require('deepmerge')(defaultConf, {
  ENV: 'dev',
  BO_URL: 'ephj2019-data-dev.mobile-spot.com',
  // no trailing '/'
  BO_PATH: '/home/www/ephj/ephj2019/ephj2019-data-dev.mobile-spot.com',
  // no trailing '/'
  BO_SERVER_ALIAS: 'kim1',
  DEPLOY_PATH: '~/ephj/ephj2019/ephj2019-dev.mobile-spot.com',
  GOOGLE_ANALYTICS_ID: 'UA-48506479-24',
  projectId: 1017555973100,
  // FCM sender id
  appId: '9C686-109FC',
  // pushwoosh appid
  DOWNLOAD_FAVORITES: {
    FEATURE_ENABLED: true
  },
  SOCIAL: {
    FEATURE_ENABLED: true,
    // FACEBOOK: {
    //     POST_PER_PAGE: 5
    // },
    TWITTER: {
      POST_PER_PAGE: 10
    },
    // INSTAGRAM: {
    //     POST_PER_PAGE: 5
    // },
    // YOUTUBE: {
    //     POST_PER_PAGE: 5
    // },
    // LINKEDIN: {
    //     POST_PER_PAGE: 10
    // },
    URL: "https://".concat(NODE_BACKEND_DOMAIN, "/social")
  },
  SYNCHRONIZED_FAVORITES: {
    FEATURE_ENABLED: true,
    URL: "https://".concat(NODE_BACKEND_DOMAIN, "/synchro-favorites")
  },
  REAL_TIME: {
    URL: "https://".concat(NODE_WS_BAKEND_DOMAIN)
  }
});