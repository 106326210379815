
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { AlertDialog } from 'react-onsenui';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import Url from 'src/components/url/Url';
import * as LoginService from 'src/core/login/LoginService';
import { iosHackScrollTo0 } from 'src/core/util/browser';

import * as actions from 'src/store/actions';

import './LoginDialog.scss';
import '../genericModal.scss';


export const COMPONENT_KEY = 'LoginDialog';
export const CONTAINER_DOM_ID = 'login-dialog-container';


const DEFAULT_DIALOG_PROPS = {
    isCancelable: true,
    animation   : 'default', // or 'none', no other choice sadly
};

/**
 * Display a dialog (modal) allowing user to login
 * @param  {object} props
 */
class LoginDialog extends Component {

    state = {
        login: '',
        password: ''
    }

    dialogProps = () => Object.assign({}, DEFAULT_DIALOG_PROPS, {
        isOpen  : this.props.isOpen,
        onCancel: this.props.actions.hideLoginDialog, // important, to update `isOpen` value in all cases
    })

    handleLoginChange = (event) => {
        this.setState({ login: event.target.value });
    }

    handlePasswordChange = (event) => {
        this.setState({ password: event.target.value });
    }

    handleLoginClicked = () => {
        if (this.props.isLoging) {
            return;
        }
        if (!this.loginField.value || !this.passwordField.value) {
            this.props.actions.showNotification({ message: this.props.labels.login.fillTheFields });
            return;
        }
        this.props.actions.startLogin(this.loginField.value, this.passwordField.value);
    }

    handleLogoutClicked = () => {
        LoginService.logout();
    }

    handleProfileClicked = () => {
        this.props.actions.hideLoginDialog();
        this.props.actions.showProfileDialog();
    }

    /*componentWillReceiveProps(nextProps) {
        if (this.props.isOpen !== nextProps.isOpen || this.props.identification !== nextProps.identification) {
            this.fillFields();
        }
    }*/

    setLoginField = el => {
        this.loginField = el;
    }

    setPasswordField = el => {
        this.passwordField = el;
    }

    render() {
        return (
            <AlertDialog {...this.dialogProps()}>
                <div id="login-dialog" className="generic-modal">
                    <div className="title-font">{this.props.labels.login.title}</div>
                        <div className="content-font">

                        { this.props.identification === null &&
                            <div>
                                <form>
                                    <div className="login-form-row">
                                        {/* <label htmlFor="login">{this.props.labels.login.loginText}</label> */}
                                        <input
                                            className="generic-modal-input"
                                            ref={this.setLoginField}
                                            value={this.state.login}
                                            placeholder={this.props.labels.login.loginText}
                                            onChange={this.handleLoginChange}
                                            onBlur={iosHackScrollTo0}
                                            name="login"
                                            type="email" />
                                    </div>

                                    <div className="login-form-row">
                                        {/* <label htmlFor="password">{this.props.labels.login.passwordText}</label> */}
                                        <input
                                            className="generic-modal-input"
                                            ref={this.setPasswordField}
                                            value={this.state.password}
                                            placeholder={this.props.labels.login.passwordText}
                                            onChange={this.handlePasswordChange}
                                            onBlur={iosHackScrollTo0}
                                            name="password"
                                            type="password" />
                                    </div>
                                </form>
                                <div className="links">

                                    { this.props.forgottenPasswordURL &&
                                        <Url href={this.props.forgottenPasswordURL}
                                             label={this.props.labels.login.forgottenPassword} />
                                    }
                                    { this.props.createAccountURL &&
                                        <Url href={this.props.createAccountURL}
                                             label={this.props.labels.login.createAccount} />
                                    }
                                    { this.props.tosURL &&
                                        <Url href={this.props.tosURL}
                                             label={this.props.labels.login.tos} />
                                    }

                                </div>
                                <div className="generic-modal-btn-container">
                                    <div className="generic-modal-btn" onClick={this.props.actions.hideLoginDialog}>{this.props.labels.common.cancel}</div>
                                    <div
                                        className={'generic-modal-btn cta-modal-btn'+(this.props.isLoging ? ' modal-btn-disabled' : '')}
                                        onClick={this.handleLoginClicked}>{this.props.isLoging ? this.props.labels.login.loading : this.props.labels.login.loginButton}</div>
                                </div>
                                <div className="login-text">{this.props.labels.login.loginDescription}</div>
                            </div>
                        }

                        { this.props.identification !== null &&
                            <div>
                                <p>{this.props.labels.login.loginSuccess}</p>
                                {this.props.profile && this.props.profile.firstName && this.props.profile.lastName &&
                                    <p>{this.props.profile.firstName} {this.props.profile.lastName}</p>
                                }
                                <p>{this.props.labels.login.introductoryText}</p>
                                <div className="generic-modal-btn-container">
                                    {this.props.profile &&
                                        <div className="generic-modal-btn" onClick={this.handleProfileClicked}>{this.props.labels.login.profile}</div>
                                    }
                                    <div className="generic-modal-btn" onClick={this.handleLogoutClicked}>{this.props.labels.login.logout}</div>
                                </div>
                            </div>
                        }

                        { this.props.error === LoginService.ERRORS.LOGIN
                            && <div className="error-message">{this.props.labels.login.badLogin}</div>
                        }
                        { this.props.error === LoginService.ERRORS.NETWORK
                            && <div className="error-message">{this.props.labels.login.noNetwork}</div>
                        }
                    </div>
                </div>
            </AlertDialog>
        );
    }
};

LoginDialog.propTypes = {
    isOpen        : PropTypes.bool.isRequired,
    labels        : PropTypes.object.isRequired,
    actions       : PropTypes.object.isRequired,
    identification: PropTypes.string,
    profile       : PropTypes.object,
    error         : PropTypes.string,
    isLoging      : PropTypes.bool.isRequired
};

const mapStateToProps = (state, ownProps) => state[COMPONENT_KEY];
const mapDispatchToProps = dispatch => ({ actions: bindActionCreators(actions, dispatch) });

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(LoginDialog);
